.c-box {
  border: 0.5px solid #DBDBDB;
  border-radius: 5px;
  margin: 93px 14.3056% 90px 14.4444%;
  padding: 58px;
}

.c-box > .button-box {
  margin-top: 41px;
  text-align: center;
}

.c-box > .c-form-grid {
  align-items: flex-start;
  display: grid;
  gap: 40px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 16px 36px -16px;
}

.c-box > .c-form-grid.col2 {
  grid-template-columns: 1fr 1fr;
}

.c-box .checkbox-form-control {
  margin-bottom: 36px;
}

.c-box .checkbox-form-control.tnc {
  margin-bottom: 0;
}

.c-box .checkbox-form-control > legend {
  color: #234171;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 13px;
}

.c-box .checkbox-form-control > .checkbox-form-group .MuiFormControlLabel-label {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
}

.c-box .checkbox-form-control > .checkbox-form-group .MuiFormHelperText-root {
  margin: 3px 0 6px 0;
}

.c-box .checkbox-form-control > .checkbox-form-group.tnc {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.c-box .radio-form-control {
  margin-bottom: 36px;
}

.c-box .radio-form-control > legend {
  color: #234171;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 13px;
}

.c-box .radio-form-control > .MuiFormHelperText-root {
  margin: 3px 0 6px 0;
}

.c-box .radio-form-control > .radio-button-group > .radio {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
}

.c-column-card-grid {
  margin-top: 77px;
}

.c-column-card-grid .ax-column-card-grid {
  margin: 0 14.4444% 0 14.6528%;
}

.c-left-image-full-width {
  margin-top: 110px;
}

.c-left-title-two-card {
  margin: 113px 14.6528% 0 14.3055%;
}

.c-note {
  margin-top: 39px
}

.c-note > .text {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  margin: 0 23.2639% 0 23.1944%;
  text-align: center;
}

.c-select-form-type {
  margin-top: 38px;
  text-align: center;
}

.c-select-form-type > .toggle-button-group .img {
  height: auto;
  margin-right: 16px;
  width: 24px;
}

.c-steps-block {
  margin: 58px 13.1944% 0 13.1944%;
  padding-bottom: 102px;
}

.c-steps-block > .ax-steps-block {
  display: flex;
  flex-wrap: wrap;
}

.c-steps-block > .ax-steps-block > .step {
  flex: 1;
}

.c-steps-block > .ax-steps-block > .step > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 22px;
  margin: 0 18px;
  text-align: center;
}

.c-steps-block > .ax-steps-block > .step > .description > a {
  color: #244171;
  text-decoration: none;
  text-decoration-color: #244171;
}

.c-steps-block > .ax-steps-block > .step > .image-box {
  display: flex;
  height: 88px;
  justify-content: center;
  margin-bottom: 46px;
  width: auto;
}

.c-title-image-title-list {
  background-image: linear-gradient(180deg, #4378E0 0%, #2965D9 100%);
  border-radius: 20px 20px 0px 0px;
  margin-top: 113px;
  padding: 131px 0 167px 0;
}

.c-title-image-title-list > .image-title-list-two-column-card {
  margin: 40px 14.6528% 0 14.4444%;
}

.c-title-image-title-list > .title {
  margin: 0 20.2083% 0 19.7222%;
}

.c-title-image-title-list > .title > .ax-title > .main {
  color: #F2F7FD;
  font-size: 40px;
  line-height: 47px;
}

.c-title-image-title-list > .title > .ax-title > .main > .span {
  color: #4AF7D9;
}

.c-title-image-title-list > .title > .ax-title > .sub {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 19px;
}

.c-title2 {
  margin-top: 98px;
}

.c-title2 > .ax-title > .main {
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 22px;
  text-transform: capitalize;
}

.c-title2 > .ax-title > .sub {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.c-title3-box {
  background-image: linear-gradient(0, #F1F8FF 0%, rgba(255, 255, 255, 0) 100%);
}

.c-title3 {
  margin-top: 156px;
}

.c-title3 > .ax-title > .main {
  font-size: 40px;
  line-height: 47px;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.c-title3 > .ax-title > .sub {
  color: #506A96;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 0 19.8611% 0 19.7917%;
}

.c-title4 {
  margin-top: 110px;
}

.c-title4 > .ax-title > .main {
  font-size: 40px;
  line-height: 47px;
  margin: 0 17.5% 24px 17.5694%;
  text-transform: capitalize;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.cu-rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.cu-success-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 108px 0 128px 0;
  text-align: center;
}

.cu-success-box > .image-box {
  margin-bottom: 40px;
}

.cu-success-box > .image-box > .img-success {
  height: auto;
  width: 371px;
}

.cu-success-box > .title-box > .title {
  color: #254476;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 8px;
}

.cu-success-box > .title-box > .title > .highlight {
  color: #4378DF;
}

.cu-success-box > .title-box > .subtitle {
  color: #506A96;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 959px) {
  .c-box > .c-form-grid.col2 {
    grid-template-columns: 1fr;
  }

  .c-left-image-full-width {
    margin: 110px 7.2222% 0 7.2222%;
  }
}

@media screen and (max-width: 699px) {
  .c-steps-block > .ax-steps-block > .step {
    display: flex;
    min-width: 100%;
  }

  .c-steps-block > .ax-steps-block > .step:not(:last-child) {
    margin-bottom: 50px;
  }

  .c-steps-block > .ax-steps-block > .step > .description {
    line-height: 20px;
    margin: 0;
    text-align: left;
  }

  .c-steps-block > .ax-steps-block > .step > .image-box {
    height: 78px;
    justify-content: flex-start;
    margin: 0 22px 0 0;
  }
}

@media screen and (max-width: 639px) {
  .c-box {
    margin: 40px 7.7295% 20px 7.7295%;
    padding: 27px 20px 31px 20px;
  }

  .c-box > .button-box {
    margin-top: 20px;
    text-align: center;
  }

  .c-box > .button-box > .MuiButton-root {
    width: 100%;
  }

  .c-box > .c-form-grid {
    row-gap: 13px;
    margin-bottom: 13px;
  }

  .c-column-card-grid {
    margin-top: 34px;
  }

  .c-column-card-grid .ax-column-card-grid {
    margin: 0 7.2464%;
  }

  .c-left-image-full-width {
    margin: 27px 0 0 0;
  }

  .c-left-image-full-width > .ax-left-image-full-width > .text-box {
    margin: 0 7.7295%;
  }

  .c-left-title-two-card {
    margin: 44px 7.7295%;
  }

  .c-left-title-two-card > .ax-left-title-two-card > .title {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }

  .c-note > .text {
    margin: 0 7.2464%;
  }

  .c-steps-block {
    margin: 43px 7.7295% 0 7.7295%;
    padding-bottom: 60px;
  }

  .c-select-form-type {
    margin-top: 33px;
  }

  .c-select-form-type > .toggle-button-group .img {
    height: auto;
    margin-right: 16px;
    width: 24px;
  }

  .c-select-form-type > .toggle-button-group > .MuiButtonBase-root  {
    padding: 17px 20px;
  }

  .c-title-image-title-list {
    background-image: linear-gradient(180deg, #4378E0 0%, #2965D9 100%);
    border-radius: 20px 20px 0px 0px;
    margin-top: 51px;
    padding: 44px 0 52px 0;
  }

  .c-title-image-title-list > .image-title-list-two-column-card {
    margin: 18px 7.2464%;
  }

  .c-title-image-title-list > .image-title-list-two-column-card > .ax-image-title-list-two-column-card > .card {
    padding: 60px 34px 36px 34px;
  }

  .c-title-image-title-list > .image-title-list-two-column-card > .ax-image-title-list-two-column-card > .card:not(:last-child) {
    margin-bottom: 31px;
  }

  .c-title-image-title-list > .title {
    margin: 0;
  }

  .c-title-image-title-list > .title > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 13px 7.7295%;
  }

  .c-title-image-title-list > .title > .ax-title > .sub {
    line-height: 24px;
    margin: 0 7.2464%;
  }

  .c-title2 {
    margin-top: 33px;
  }

  .c-title2 > .ax-title > .main {
    font-size: 40px;
    line-height: 47px;
    margin: 0 7.7295% 20px 7.7295%;
  }

  .c-title2 > .ax-title > .sub {
    margin: 0 7.7295%;
  }

  .c-title3 {
    margin-top: 27px;
  }

  .c-title3 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .c-title3 > .ax-title > .sub {
    margin: 0 7.4879%;
  }

  .c-title4 {
    margin-top: 110px;
  }

  .c-title4 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.4879% 33px 7.4879%;
  }

  .c-title4 > .ax-title > .sub {
    color: #506A96;
    font-size: 16px;
    line-height: 24px;
    margin: 0 7.2464%;
  }
}

@media screen and (max-width: 399px) {
  .c-select-form-type > .toggle-button-group .img {
    display: none;
  }

  .c-select-form-type > .toggle-button-group > .MuiButtonBase-root  {
    padding: 17px 15px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .c-box {
    margin: 93px 7.1528% 90px 7.2222%;
  }

  .c-column-card-grid .ax-column-card-grid {
    margin: 0 7.2222% 0 7.3264%;
  }

  .c-left-title-two-card {
    margin: 113px 7.3264% 0 7.1528%;
  }

  .c-note > .text {
    margin: 0 11.632% 0 11.5972%;
  }

  .c-steps-block {
    margin: 58px 7.2222% 0 7.2222%;
  }

  .c-title-image-title-list > .image-title-list-two-column-card {
    margin: 40px 7.3264% 0 7.2222%;
  }

  .c-title-image-title-list > .image-title-list-two-column-card > .ax-image-title-list-two-column-card > .card {
    padding: 30px 21px 19px 21px;
  }

  .c-title-image-title-list > .title {
    margin: 0 10.1042% 15px 9.8611%;
  }

  .c-title3 > .ax-title > .sub {
    margin: 0 9.93056% 0 9.8958%;
  }

  .c-title4 > .ax-title > .main {
    margin: 0 8.75% 24px 8.7847%;
  }
}
