.ax-left-right-nested-list-block > .ax-left-nested-list-block:not(:last-child),
.ax-left-right-nested-list-block > .ax-right-nested-list-block:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 959px) {
  .ax-left-right-nested-list-block > .ax-left-nested-list-block:not(:last-child),
  .ax-left-right-nested-list-block > .ax-right-nested-list-block:not(:last-child) {
    /* margin-bottom: 36px; */
  }
}
