.ax-header {
  background-image: linear-gradient(90deg, #4276db 0%, #254578 100%);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  > .nav-bar {
    background-color: #ffffff;
    height: 80px;
    position: relative;
    > .menu-bar {
      align-items: stretch;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
      display: flex;
      height: calc(100% - 2px);
      justify-content: space-between;
      max-height: 80px;
      padding: 0 14.4444%;
      > .button-box {
        align-self: center;
        > .btn-link {
          background-image: linear-gradient(95.15deg, #46e6da -15.35%, #4382df 100%);
          border-radius: 5px;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          line-height: 19px;
          padding: 13px 8px;
          text-align: center;
          text-transform: none;
        }
      }
      > .logo-box {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-right: 16px;
        > .img {
          height: auto;
          max-width: 58px;
        }
      }
      > .logo-box.mobile {
        align-items: center;
        display: none;
        justify-content: center;
        margin-right: 0;
        padding: 0;
      }
      > .menu {
        align-items: center;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        min-height: 80px;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: #90a4ae #f5f5f5;
        &::-webkit-scrollbar {
          width: 9px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #90a4ae;
          border-radius: 4px;
          border: 2px solid #f5f5f5;
        }
        > .item {
          align-items: center;
          display: flex;
          height: 100%;
          max-height: 80px;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #487fed;
            width: 0;
          }

          &:hover {
            &:after {
              width: 100%;
              transition: 0.25s ease-in-out;
            }
          }

          > .link-box {
            align-items: center;
            border-bottom: 3px solid transparent;
            color: #234171;
            display: flex;
            font-weight: 500;
            height: calc(100% - 3px);
            justify-content: center;
            padding: 0 10px;
            display: flex;
            align-items: center;

            > a {
              color: #234171;
              font-size: 16px;
              font-weight: 500;
              line-height: 19px;
              text-align: center;
              text-decoration: none;
              text-decoration-color: #234171;
              &:active {
                color: #487fed;
                font-weight: bold;
                text-decoration-color: #487fed;
              }
            }
            > span {
              cursor: default;
            }

            &.active {
              color: #487fed;
            }

            .dropdown-append-icon {
              font-size: 1.2rem;
            }
          }
          &:hover {
            > .link-box {
              // border-bottom: 3px solid #1a3053;

              > a {
                color: #1a3053;
                text-decoration-color: #1a3053;
              }
              > span {
                color: #1a3053;
                text-decoration-color: #1a3053;
              }
            }
            > .link-box.active {
              border-bottom: 3px solid #1a3053;
            }
          }
          > .link-box.active {
            border-bottom: 3px solid #487fed;

            > a {
              color: #487fed;
              font-weight: bold;
              text-decoration-color: #487fed;
            }
            > span {
              color: #487fed;
              font-weight: bold;
              text-decoration-color: #487fed;
            }
          }
        }
      }
    }
    > .menu-bar.menu-bar-wrap {
      align-items: stretch;
      display: none;
      justify-content: space-between;
      padding: 0 14.4444%;
    }
    > .mobile-menu-bar {
      display: none;
    }
  }
  > .top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 14.4444%;
    > .buttons {
      align-items: center;
      display: flex;
      > .button-box {
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        padding: 10px 26px;
        text-align: center;
        > a {
          text-decoration: none;
        }

        &.active {
          background-color: #ffffff;
          &:hover {
            background-color: #ebebeb;
            color: #1a3053;
            transition: background-color 0.3s ease-in;
          }
          > a {
            color: #234171;
            text-decoration-color: #234171;
          }
        }

        &:not(.active) {
          &:hover {
            background-color: #2760ce;
            color: #ebebeb;
            transition: background-color 0.3s ease-in;
          }
          > a {
            color: #ffffff;
            text-decoration-color: #ffffff;
          }
        }
      }
    }
    > .icons {
      align-items: center;
      display: flex;
      > .icon-box {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 8px 0;
        &:not(:last-child) {
          margin-right: 10px;
        }
        > a {
          height: 24px;
          width: 24px;
          .img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .ax-header {
    > .nav-bar {
      height: 70px;
      > .menu-bar {
        padding: 0 7.2464%;
        box-shadow: 0 2px 2px rgb(0 0 0 / 8%) !important;
        > .button-box {
          display: none;
        }
        > .menu {
          display: none;
        }
        > .logo-box.mobile {
          display: flex;
        }
      }
      > .mobile-menu-bar.expand {
        background-color: #ffffff;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
        display: block;
        left: 0;
        min-height: calc(100vh - 70px);
        position: relative;
        top: 2px;
        z-index: 1;
      }
      > .mobile-menu-bar {
        > .mobile-menu {
          > .menu {
            background-color: #ffffff;
            padding: 16px 7.2464%;
            > .item {
              > .link-box {
                align-items: center;
                background-color: #ffffff;
                color: #234171;
                display: flex;
                font-weight: 500;
                justify-content: space-between;
                > a {
                  color: #234171;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19px;
                  text-align: center;
                  text-decoration: none;
                  text-decoration-color: #234171;
                  &:active {
                    color: #4277dd;
                    font-weight: bold;
                    text-decoration-color: #4277dd;
                  }
                  &:hover {
                    color: #1a3053;
                    text-decoration-color: #1a3053;
                  }
                }
                > span {
                  cursor: default;
                }
              }
              > .link-box.active {
                > a {
                  color: #4277dd;
                  font-weight: bold;
                  text-decoration-color: #4277dd;
                }
                > span {
                  color: #4277dd;
                  font-weight: bold;
                  text-decoration-color: #4277dd;
                }
              }
            }
          }
          > .sub-menu {
            background-color: #f0f9fe;
            display: none;
            padding: 20px 7.2464%;
            > .item {
              > .link-box {
                align-items: center;
                color: #102f5b;
                display: flex;
                font-weight: 500;
                justify-content: space-between;
                padding: 8px 0;
                > a {
                  color: #102f5b;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19px;
                  text-align: center;
                  text-decoration: none;
                  text-decoration-color: #102f5b;
                  &:active {
                    color: #4276db;
                    font-weight: bold;
                    text-decoration-color: #4276db;
                  }
                  &:hover {
                    color: #0a1e3a;
                    text-decoration-color: #0a1e3a;
                  }
                }
                > span {
                  cursor: default;
                }
              }
              > .link-box.active {
                > a {
                  color: #4276db;
                  font-weight: bold;
                  text-decoration-color: #4276db;
                }
                > span {
                  color: #4276db;
                  font-weight: bold;
                  text-decoration-color: #4276db;
                }
              }
            }
          }
          > .sub-menu.expand {
            display: block;
          }
        }
      }
      > .sub-menu-bar {
        display: none;
      }
    }
    > .top-bar {
      padding-left: 0 !important;
      padding-right: 15px !important;

      > .buttons {
        > .button-box {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .menu-bar.menu-bar-wrap {
    display: none !important;
  }
}
@media screen and (min-width: 921px) and (max-width: 1240px) {
  .ax-header {
    > .nav-bar {
      height: 160px;
      > .menu-bar {
        box-shadow: none;
        > .button-box {
          display: none;
        }
      }
      > .menu-bar.menu-bar-wrap {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
        display: flex;
        height: 78px;
        > .button-box {
          display: flex;
        }
      }
    }
  }
}
@media screen and (max-width: 1064px) {
  .ax-header {
    > .nav-bar {
      > .menu-bar {
        padding: 0 7.2222%;
      }
      > .menu-bar.menu-bar-wrap {
        padding: 0 7.2222%;
      }
    }
    > .top-bar {
      padding: 0 7.2222%;
    }
  }
}
