.ax-two-column-cards {
  display: grid;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  grid-gap: 32px 31px;
  grid-template-columns: 1fr 1fr;
}

.ax-two-column-cards > .card {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 37px 50px 37px 30px;
}

.ax-two-column-cards > .card > .image-box {
  margin-right: 31px;
}

.ax-two-column-cards > .card > .image-box > .img {
  height: 65px;
  width: 65px;
}

.ax-two-column-cards > .card > .image-box > .title.mobile {
  display: none;
}

.ax-two-column-cards > .card > .image-box,
.ax-two-column-cards > .card > .text-box {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.ax-two-column-cards > .card > .image-box > .title.mobile,
.ax-two-column-cards > .card > .text-box > .title {
  font-weight: bold;
}

@media screen and (max-width: 639px) {
  .ax-two-column-cards {
    grid-template-columns: 1fr;
  }

  .ax-two-column-cards > .card {
    background-color: #FFFFFF;
    border: 1px solid #D7D7D7;
    border-radius: 10px;
    padding: 19px 22px 19px 32px;
  }
}

@media screen and (max-width: 799px) {
  .ax-two-column-cards > .card {
    align-items: flex-start;
    flex-direction: column;
  }

  .ax-two-column-cards > .card > .image-box {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
  }

  .ax-two-column-cards > .card > .image-box > .img {
    height: 55px;
    width: 55px;
    margin-right: 14px;
  }

  .ax-two-column-cards > .card > .image-box > .title.mobile {
    display: block;
  }

  .ax-two-column-cards > .card > .text-box > .title {
    display: none;
  }
}
