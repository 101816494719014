.snfb-box {
  background-image: linear-gradient(180deg, #F3F6FC 0%, rgba(243, 246, 252, 0) 104.88%);
  margin-top: 246px;
  padding-top: 94px;
}

.snfb-left-cards-image-block {
  margin-top: 53px;
}

.snfb-left-cards-image-block .ax-left-cards-image-block {
  align-items: center;
  display: flex;
  flex-wrap: wrap-reverse;
  margin: 0 12.4306%;
}

.snfb-left-cards-image-block .ax-left-cards-image-block > .card-box {
  flex: 1;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card {
  align-items: center;
  background-color: #FFFFFF;
  border-top: 6px #47DBDB solid;
  border-radius: 10px;
  color: #506A96;
  display: flex;
  padding: 34px 25px;
}

.snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card.c1 {
  background-image: linear-gradient(262.86deg, #4378DF 42.22%, #49F7D9 132.91%);
  border-top: none;
  color: #FFFFFF;
}

.snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card:not(:last-child) {
  margin-bottom: 24px;
}

.snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card > .description {
  margin: 0 39px 0 23px;
}

.snfb-left-cards-image-block .ax-left-cards-image-block > .image-box {
  margin-left: 53px;
}

.snfb-left-content-image-card-block {
  margin: 90px 6.8056% 0 6.875%;
}

.snfb-left-right-content-block {
  margin-top: 140px;
}

.snfb-left-right-content-block > .ax-left-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.snfb-text {
  color: #506A96;
  margin-top: 42px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}

.snfb-title > .ax-title > .main {
  color: #254476;
  margin-bottom: 8px;
}

.snfb-title2 {
  display: none;
  margin-top: 48px;
}

.snfb-title2 > .ax-title > .main {
  color: #263238;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 0;
}

.snfb-two-column-full-width {
  margin-top: 140px;
}

@media screen and (max-width: 1365px) {
  .snfb-left-cards-image-block .ax-left-cards-image-block > .image-box > .img {
    height: auto;
    width: 532px;
  }
}

@media screen and (max-width: 1280px) {
  .snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card {
    padding: 17px 12.5px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card > .description {
    margin: 0 19.5px 0 11.5px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .image-box {
    margin-left: 21.5px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .image-box > .img {
    width: 473px;
  }
}

@media screen and (max-width: 899px) {
  .snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card {
    padding: 34px 25px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card > .description {
    margin: 0 39px 0 23px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .image-box {
    margin: 0 0 15px 0;
    min-width: 100%;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .image-box > .img {
    width: 100%;
  }
}

@media screen and (max-width: 639px) {
  .snfb-box {
    margin-top: 67px;
    padding-top: 53px;
  }

  .snfb-left-cards-image-block {
    margin-top: 53px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block {
    margin: 0 5.314%;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card {
    padding: 27px 25px;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block > .card-box > .card > .description {
    margin: 0 17px 0 23px;
  }

  .snfb-left-content-image-card-block {
    margin: 34px 7.7295% 0 7.7295%;
  }

  .snfb-left-right-content-block {
    margin-top: 40px;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.7295%;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block {
    flex-wrap: wrap;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block {
    flex-wrap: wrap-reverse;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block:not(:last-child),
  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
    margin-bottom: 40px;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .image-box,
  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .image-box {
    margin-bottom: 0;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box,
  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box {
    text-align: center;
    margin-bottom: 40px;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .list-box,
  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .list-box {
    margin-top: 0;
    text-align: left;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .title1,
  .snfb-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .title1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 31px;
  }

  .snfb-text {
    margin: 60px 7.7295% 0 7.7295%;
  }

  .snfb-title > .ax-title > .main {
    color: #254476;
    margin-bottom: 8px;
  }

  .snfb-title2 {
    display: block;
  }

  .snfb-two-column-full-width {
    margin-top: 41px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .snfb-left-content-image-card-block {
    margin: 90px 3.4028% 0 3.4375%;
  }

  .snfb-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }

  .snfb-left-cards-image-block .ax-left-cards-image-block {
    display: flex;
    margin: 0 6.2153%;
  }
}
