.ax-column-card-grid2 {
  display: grid;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  gap: 39px 24px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
}

.ax-column-card-grid2 > .item {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 37px 40px 45px 40px;
}

.ax-column-card-grid2 > .item > .top-box {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.ax-column-card-grid2 > .item > .top-box > .image-box {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 30px;
}

.ax-column-card-grid2 > .item > .top-box > .image-box > .img {
  height: auto;
  width: 95px;
}

.ax-column-card-grid2 > .item > .top-box > .title {
  color: #234171;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.ax-column-card-grid2 > .item > .text-box > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1064px) {
  .ax-column-card-grid2 > .item {
    padding: 18.5px 20px 22.5px 20px;
  }
}
