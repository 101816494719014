// a scss file to patch mobile view css

$customSpacing: 30px;
$customStart: 1;
$customEnd: 9;
$productPages: a, b, c, w;
$typographyTypes: title, text;
$solutionPages: r, bh, fb, hl, wc, mc;
$servicePages: ac, h, pi, ax;

@mixin overwriteSpacing {
  margin: 0;
  margin-top: $customSpacing;
  padding: 0 $customSpacing;
}

@mixin overwriteSpacingWithoutMarginTop {
  margin: 0;
  padding: 0 $customSpacing;
}

@media screen and (max-width: 725px) {
  .plan {
    &.mobile {
      .top-box {
        min-height: auto;

        .button-box {
          margin-top: 10px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .content {
    @include overwriteSpacing;
  }

  .p {
    &a {
      @each $type in $productPages {
        &#{$type} {
          &-steps,
          &-two-column-cards,
          &-right-list-block,
          &-video-box,
          &-left-right-nested-list-block,
          &-column-card-grid,
          &-borderless-column-card-grid {
            @include overwriteSpacing;
          }

          &-right-list-block {
            > .item {
              &:not(:last-child) {
                margin-bottom: 0;
              }
            }
          }

          @each $typoType in $typographyTypes {
            @for $i from $customStart through $customEnd {
              &-#{$typoType}#{$i} {
                @include overwriteSpacing;
              }
            }
          }
        }
      }
    }

    &o {
      &-title {
        margin-top: $customSpacing;
      }
    }

    @for $i from $customStart through $customEnd {
      &-title#{$i} {
        margin-top: $customSpacing;

        > .ax-title {
          > .main {
            margin: 0;
            margin-bottom: $customSpacing;
            padding: 0 $customSpacing;
          }
        }
      }
    }
  }

  .ax {
    &-title {
      &-with-button {
        min-height: initial;

        > .title {
          padding-top: 0;
        }

        @for $i from $customStart through $customEnd {
          &#{$i} {
            .title {
              padding-top: $customSpacing;
            }
          }
        }
      }

      > .sub {
        margin: 0 !important;
        padding: 0 $customSpacing;
      }
    }

    &-borderless-column-card-grid {
      gap: $customSpacing;
    }

    &-left-nested-list-block,
    &-steps {
      @include overwriteSpacingWithoutMarginTop;
    }

    &-left {
      &-title-two-card {
        > .title {
          margin-bottom: $customSpacing;
        }
      }

      &-image-full-width {
        > .text-box {
          margin: 0 !important;
          padding: 0 $customSpacing;
        }
      }

      &-content-image-card-block {
        padding: $customSpacing;
      }
    }

    &-column-card-grid {
      row-gap: $customSpacing !important;
    }

    &-header-hero {
      padding: $customSpacing;
    }
  }

  .hp {
    @for $i from $customStart through $customEnd {
      &-title#{$i} {
        @include overwriteSpacing;
      }
    }

    &-main-banner {
      margin: $customSpacing 0;
    }

    &-video-box {
      margin-top: $customSpacing;
      padding: $customSpacing;
    }
  }

  .cu {
    &-title {
      @include overwriteSpacing;
    }

    &-box {
      margin: $customSpacing;
      padding: $customSpacing;
    }
  }

  .c {
    &-left-title-two-card {
      margin: $customSpacing 0;
      padding: 0 $customSpacing;
    }

    &-title-image-title-list {
      padding: $customSpacing 0;

      > .image-title-list-two-column-card {
        > .ax-image-title-list-two-column-card {
          > .card {
            padding: $customSpacing;
          }
        }
      }
    }

    &-note {
      margin-top: $customSpacing;

      > .text {
        margin: 0;
        padding: 0 $customSpacing;
      }
    }

    &-box {
      margin: $customSpacing;
      padding: $customSpacing;
    }

    &-steps-block {
      > .ax-steps-block {
        > .step {
          > .image-box {
            height: auto;
            display: block;

            img {
              max-width: 70px;
            }
          }
        }
      }
    }

    @for $i from $customStart through $customEnd {
      &-title#{$i} {
        margin-top: $customSpacing;

        > .ax-title {
          > .main {
            margin: 0;
            margin-bottom: $customSpacing;
            padding: 0 $customSpacing;
          }
        }
      }
    }
  }

  @each $solutionPage in $solutionPages {
    .sn#{$solutionPage} {
      &-title {
        margin-top: $customSpacing;
      }

      &-text {
        @include overwriteSpacing;
      }

      @for $i from $customStart through $customEnd {
        &-title#{$i} {
          margin-top: $customSpacing;
        }
      }

      &-left {
        &-image-column-cards-block,
        &-content-image-card-block {
          @include overwriteSpacing;
        }

        &-right-content-block {
          margin-top: $customSpacing;

          > .ax-left-right-content-block {
            margin: 0;
            padding: 0 $customSpacing;
          }
        }
      }

      &-image-right-title-description-card {
        @include overwriteSpacing;
      }

      @for $i from $customStart through $customEnd {
        &-image-right-title-description-card#{$i} {
          @include overwriteSpacing;
        }

        &-title#{$i} {
          > .ax-title {
            .main {
              margin: 0;
              margin-bottom: $customSpacing;
              padding: 0 $customSpacing;
            }
          }
        }
      }
    }
  }

  @each $servicePage in $servicePages {
    .s#{$servicePage} {
      &-right-content-block,
      &-left-right-content-block {
        margin-top: $customSpacing;
      }

      &-left {
        &-right-content-block {
          > .ax-left-right-content-block {
            @include overwriteSpacingWithoutMarginTop;
          }
        }

        &-content-button-card {
          margin: $customSpacing;
        }

        &-icon-card2 {
          @include overwriteSpacing;
        }
      }

      &-image-title-description-card,
      &-disclaimer,
      &-image-right-title-description-card,
      &-terms {
        @include overwriteSpacing;
      }

      &-box {
        margin: $customSpacing;
      }

      &-note {
        @include overwriteSpacing;
        margin-bottom: $customSpacing;
      }

      @for $i from $customStart through $customEnd {
        &-title#{$i},
        &-right-content-block#{$i} {
          margin-top: $customSpacing;

          > .ax-title {
            > .main {
              margin: 0;
              margin-bottom: $customSpacing;
              padding: 0 $customSpacing;
            }
          }

          > .ax-right-content-block {
            margin: 0;
            padding: 0 $customSpacing;
          }
        }
      }
    }
  }
}
