.ax-right-nested-list-block {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-wrap: wrap;
  margin-right: 14.4444%;
}

.ax-right-nested-list-block > .image-box > .img {
  height: auto;
  max-width: 524px;
}

.ax-right-nested-list-block > .text-box {
  flex: 1;
  margin-left: 143px;
}

.ax-right-nested-list-block > .text-box > .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 19px;
}

.ax-right-nested-list-block > .text-box > .list-box > .item {
  border-bottom: 1px solid #A2C5FC;
  display: flex;
  padding: 20px 0;
  cursor: pointer;
}

.ax-right-nested-list-block > .text-box > .list-box > .item > .number-box {
  margin-right: 19px;
}

.ax-right-nested-list-block > .text-box > .list-box > .item > .number-box > .number {
  color: #46D3DA;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.ax-right-nested-list-block > .text-box > .list-box > .item > .text-box {
  flex: 1;
}

.ax-right-nested-list-block > .text-box > .list-box > .item > .text-box > .text {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.ax-right-nested-list-block > .text-box > .list-box > .item > .text-box > .top-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ax-right-nested-list-block > .text-box > .list-box > .item > .text-box > .top-box > .title {
  color: #4F80E1;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.ax-right-nested-list-block > .text-box > .list-box > .item.close > .text-box > .text {
  display: none;
}

.ax-right-nested-list-block > .text-box > .list-box > .item.close > .text-box > .top-box {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .ax-right-nested-list-block {
    margin: 0 7.2222%;
  }

  .ax-right-nested-list-block > .image-box {
    display: none;
  }

  .ax-right-nested-list-block > .text-box {
    margin-left: 0;
  }

  .ax-right-nested-list-block > .text-box > .title {
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 639px) {
  .ax-right-nested-list-block {
    margin: 0 7.7295%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1064px) {
  .ax-right-nested-list-block {
    margin-right: 7.2222%;
  }

  .ax-right-nested-list-block > .image-box > .img {
    max-width: 314px;
  }

  .ax-right-nested-list-block > .text-box {
    margin-left: 71px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1064px) {
  .ax-right-nested-list-block > .image-box > .img {
    max-width: 367px;
  }
}

@media screen and (min-width: 1065px) and (max-width: 1129px) {
  .ax-right-nested-list-block > .image-box > .img {
    max-width: 419px;
  }
}

@media screen and (min-width: 1130px) and (max-width: 1279px) {
  .ax-right-nested-list-block > .image-box > .img {
    max-width: 472px;
  }
}
