.ax-left-icon-card {
  align-items: center;
  display: flex;
  padding: 18px 25px 18px 31px;
}

.ax-left-icon-card > .img {
  height: 26px;
  margin-right: 27px;
  width: 26px;
}

.ax-left-icon-card > .text-box {
  color: #254576;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

.ax-left-icon-card > .text-box > .title {
  font-weight: bold;
}
