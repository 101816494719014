.snr-image-right-title-description-card1 {
  margin: 47px 14.375% 0 14.4444%;
}

.snr-image-right-title-description-card1 > .ax-column-card-grid2 {
  gap: 34px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.snr-image-right-title-description-card1 > .ax-column-card-grid2 > .item {
  border-bottom: solid 7px#47DBDB;
  padding: 20px 17px;
}

.snr-image-right-title-description-card1 > .ax-column-card-grid2 > .item > .text-box {
  text-align: center;
}

.snr-image-right-title-description-card1 > .ax-column-card-grid2 > .item > .top-box > .image-box {
  margin: 0;
}

.snr-image-right-title-description-card1 > .ax-column-card-grid2 > .item > .top-box > .image-box > img {
  border-radius: 5px;
  width: 100%;
}

.snr-image-right-title-description-card2 {
  margin: 47px 14.375% 0 14.4444%;
}

.snr-image-right-title-description-card2 > .ax-column-card-grid2 {
  gap: 46px 44px;
}

.snr-image-right-title-description-card2 > .ax-column-card-grid2 > .item {
  align-items: center;
  display: flex;
  padding: 35px 20px;
}

.snr-image-right-title-description-card2 > .ax-column-card-grid2 > .item > .top-box > .image-box {
  margin-right: 15px;
}

.snr-image-right-title-description-card3 {
  margin: 47px 14.375% 0 14.4444%;
}

.snr-image-right-title-description-card3 > .ax-column-card-grid2 {
  gap: 34px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.snr-image-right-title-description-card3 > .ax-column-card-grid2 > .item {
  border-bottom: solid 7px#47DBDB;
  padding: 31px 33px;
}

.snr-image-right-title-description-card3 > .ax-column-card-grid2 > .item > .text-box {
  text-align: center;
}

.snr-image-right-title-description-card3 > .ax-column-card-grid2 > .item > .top-box {
  margin-bottom: 30px;
}

.snr-image-right-title-description-card3 > .ax-column-card-grid2 > .item > .top-box > .image-box {
  margin: 0 auto;
  min-height: 125px;
  text-align: center;
}

.snr-image-right-title-description-card3 > .ax-column-card-grid2 > .item > .top-box > .image-box > img {
  border-radius: 5px;
  width: 100%;
  max-height: 200px;
}

.snr-left-content-image-card-block {
  margin: 70px 6.8056% 0 6.875%;
}

.snr-left-image-column-cards-block {
  margin: 76px 14.4444% 0 14.4444%;
}

.snr-text {
  margin-top: 39px;
}

.snr-text > .ax-text > .main {
  color: #506A96;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.snr-title1 {
  margin-top: 110px;
}

.snr-title1 > .ax-title > .main {
  margin-bottom: 8px;
}

.snr-title2 {
  margin-top: 208px;
}

.snr-title2 > .ax-title > .main {
  color: #254476;
  margin-bottom: 8px;
}

.snr-title3 {
  margin-top: 135px;
}

.snr-title3 > .ax-title > .main {
  color: #254476;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 10px;
}

.snr-title4 {
  margin-top: 150px;
}

.snr-title4 > .ax-title > .main {
  color: #254476;
  margin-bottom: 13px;
}

.snr-title5 {
  display: none;
  margin-top: 36px;
}

.snr-title5 > .ax-title > .main {
  color: #263238;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 0;
}

.snr-two-column-full-width {
  margin-top: 100px;
}

@media screen and (max-width: 639px) {
  .snr-image-right-title-description-card1 {
    margin: 38px 10.8696% 0 10.8696%;
  }

  .snr-image-right-title-description-card1 > .ax-column-card-grid2 {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
  }

  .snr-image-right-title-description-card2 {
    margin: 14px 10.8696% 0 10.8696%;
  }

  .snr-image-right-title-description-card2 > .ax-column-card-grid2 {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
  }

  .snr-image-right-title-description-card2 > .ax-column-card-grid2 > .item {
    align-items: flex-start;
    flex-direction: column;
    padding: 23px 25px;
  }

  .snr-image-right-title-description-card2 > .ax-column-card-grid2 > .item > .top-box {
    margin-bottom: 12px;
  }

  .snr-image-right-title-description-card2 > .ax-column-card-grid2 > .item > .top-box > .image-box {
    justify-content: flex-start;
    margin-right: 0;
  }

  .snr-image-right-title-description-card2 > .ax-column-card-grid2 > .item > .top-box > .image-box > .img {
    width: 60%;
  }

  .snr-image-right-title-description-card3 {
    margin: 23px 10.8696% 0 10.8696%;
  }

  .snr-image-right-title-description-card3 > .ax-column-card-grid2 {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
  }

  .snr-image-right-title-description-card3 > .ax-column-card-grid2 > .item {
    padding: 30px 28px;
  }

  .snr-left-content-image-card-block {
    margin: 26px 7.7295% 0 7.7295%;
  }

  .snr-left-image-column-cards-block {
    margin: 38px 10.8696% 0 10.8696%;
  }

  .snr-left-image-column-cards-block > .ax-left-image-column-cards-block > .list-box {
    align-items: flex-start;
    grid-auto-rows: auto;
  }

  .snr-left-image-column-cards-block > .ax-left-image-column-cards-block > .list-box > .item {
    display: flex;
    padding: 23px 25px;
  }

  .snr-left-image-column-cards-block > .ax-left-image-column-cards-block > .list-box > .item > .img {
    height: 34px;
    margin: 0 19px 0 0;
    width: 34px;
  }

  .snr-text {
    margin-top: 26px;
  }

  .snr-text > .ax-text > .main {
    margin: 0 10.8696%;
  }

  .snr-title1 {
    margin-top: 40px;
  }

  .snr-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 34px 7.7295%;
  }

  .snr-title1 > .ax-title > .sub {
    line-height: 24px;
    margin: 0 10.8696%;
  }

  .snr-title2 {
    margin-top: 40px;
  }

  .snr-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .snr-title2 > .ax-title > .sub {
    line-height: 24px;
    margin: 0 7.7295%;
  }

  .snr-title3 {
    margin-top: 66px;
  }

  .snr-title3 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295%;
  }

  .snr-title4 {
    margin-top: 49px;
  }

  .snr-title4 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .snr-title4 > .ax-title > .sub {
    line-height: 24px;
    margin: 0 7.7295%;
  }

  .snr-title5 {
    display: block;
  }

  .snr-two-column-full-width {
    margin-top: 41px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .snr-image-right-title-description-card1 {
    margin: 47px 7.1875% 0 7.2222%;
  }

  .snr-image-right-title-description-card1 > .ax-column-card-grid2 {
    grid-template-columns: 1fr 1fr;
  }

  .snr-image-right-title-description-card2 {
    margin: 47px 7.1875% 0 7.2222%;
  }

  .snr-image-right-title-description-card2 > .ax-column-card-grid2 {
    grid-template-columns: 1fr 1fr;
  }

  .snr-image-right-title-description-card3 {
    margin: 47px 7.1875% 0 7.2222%;
  }

  .snr-image-right-title-description-card3 > .ax-column-card-grid2 {
    grid-template-columns: 1fr 1fr;
  }

  .snr-left-content-image-card-block {
    margin: 70px 3.4028% 0 3.4375%;
  }

  .snr-left-image-column-cards-block {
    margin: 76px 7.2222% 0 7.2222%;
  }
}
