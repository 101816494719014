@use "assets/style/index";

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#root {
  //prevent react-reveal left right transition animations to cause overflow
  overflow-x: hidden;

  > .ax-content {
    min-height: calc(100vh + 578px);
  }
}

li {
  margin-bottom: 8px;
}

/* remove arrows/spinner */
input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}
