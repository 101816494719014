@import "variables/colors.scss";

@each $type, $color in $colors {
  .color-custom-#{$type} {
    color: $color;
  }

  .bg-custom-#{$type} {
    background-color: $color;
  }
}
