.ax-column-card-flex {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
}

.ax-column-card-flex > .item {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 25px 16px;
  width: 320px;
}

.ax-column-card-flex > .item > .image-box {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ax-column-card-flex > .item > .image-box > .img {
  border-radius: 10px 10px 0 0;
  height: auto;
  width: 100%;
}

.ax-column-card-flex > .item > .text-box {
  padding: 30px 25px 55px 25px;
}

.ax-column-card-flex > .item > .text-box > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.ax-column-card-flex > .item > .text-box > .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
