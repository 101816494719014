.content {
  margin: 20px 14.375%;
}

.box-note {
  border: 1px solid #ccc;
  padding: 20px;
}

.plan {
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  color: #254578;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
  border-radius: 10px;
}

.plan > .row {
  display: grid;
  flex-direction: row;
  grid-template-columns: 28% 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #f4f4f4;
}

.plan > .row .header {
  padding: 0px;
  height: 350px;
  padding-left: 2px;
}

.plan > .row .header1 {
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0px;
}

.plan > .row .header1 .package {
  font-size: 16pt;
  font-weight: bold;
  border-bottom: 1px solid #f4f4f4;
  height: 36px;
  line-height: 36px;
  padding-top: 10px;
  border-right: 1px solid #f4f4f4;
  position: relative;
}

.plan > .row .header2 .price {
  color: #888;
  height: 100%;
  border-right: 1px solid #f4f4f4;
}

.plan > .row .header2 .price > .btn-action {
  text-transform: capitalize;
}

.plan > .row .header2 .price div {
  margin: 60px auto 50px auto;
  border: 0;
}

.plan > .row .header2 {
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0px;
  height: 300px;
}

.plan > .row .header2 b {
  font-size: 28pt;
  color: #254578;
}

.plan > .row .col-1 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 10.5pt;
  padding: 15px;
  text-align: left;
}

.plan > .row .col-customize {
  background-color: #447BE0;
  color: #FFFFFF;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
}

.plan > .row .col-highlight {
  box-shadow: 0 0 10px 5px rgba(0,0,0,0.05);
  clip-path: inset(0px -15px 0px -15px);
  padding: 10px;
}

.plan > .row .col-image {
  background-repeat: no-repeat;
  background-size: contain;
}

.plan > .row div {
  font-family: Roboto;
  font-size: 9.5pt;
  text-align: center;

}

.plan > .row .col {
  padding: 10px;
  border-right: 1px solid #f4f4f4;
}

#package-focus {
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  z-index: 1;
}

.plan .recommend {
  background-color: #4276DB;
  padding-top: 5px;
  font-size: 16pt !important;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.plan .popular {
  background-color: #19CCA3;
  font-size:9pt;
  color: #fff;
  margin-left: -10px;
  margin-right: -10px;
  height: 26px;
  border: 0px !important;
  line-height: 26px;
  text-transform: uppercase;
}

.plan .tri {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #149879;
  position: absolute;
  bottom: -5px;
  z-index: -1;
}

.plan .tri-left {
  left: -10px;
  transform: rotate(45deg);
}

.plan .tri-right {
  right: -10px;
  transform: rotate(-45deg);
}

.plan .freeOneMonth {
  width: calc(100% - 20px);
  background-color: #E8FAF6;
  color: #000;
  font-weight: bold;
  padding: 10px;
  margin: 20px auto !important;
  height: 16px;
}

.plan .freeOneMonthPlaceHolder {
  width: calc(100% - 20px);
  background-color: #fff;
  padding: 10px;
  margin: 20px auto !important;
  height: 16px;
}

.plan.mobile {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  display: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.plan.mobile > .column {
  background-color: #FFFFFF;
  border-radius: 5px;
}

.plan.mobile > .column > .bottom-box {
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 0px 0px 5px 5px;
  color: #446ee0;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 20px;
  padding: 10px 20px;
}

.plan.mobile > .column > .bottom-box:hover {
  background-color: #e0e0e0;
}

.plan.mobile > .column > .bottom-box > div {
  margin: 0 5px;
}

.plan.mobile > .column > .content-box {
  border: 1px solid #E0E0E0;
  border-bottom: 0;
  border-top: 0;
  color: #102F5B;
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 30px;
}

.plan.mobile > .column > .content-box > .row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 27px 0 23px;
}

.plan.mobile > .column > .content-box > .row > .left-box {
  margin-right: 12px;
}

.plan.mobile > .column > .content-box > .row > .left-box > .subtitle {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}

.plan.mobile > .column > .content-box > .row > .value {
  font-weight: normal;
}

.plan.mobile > .column > .content-box > .row:last-child {
  margin-bottom: 0;
}

.plan.mobile > .column > .top-box {
  border: 1px solid #E0E0E0;
  border-bottom: 0;
  border-radius: 5px 5px 0px 0px;
  color: #102F5B;
  font-weight: bold;
  padding: 29px 27px 20px 27px;
}

.plan.mobile > .column > .top-box > .button-box {
  margin-top: 13px;
  text-align: center;
}

.plan.mobile > .column > .top-box > .button-box > .btn-action {
  text-transform: capitalize;
  width: 100%;
}

.plan.mobile > .column > .top-box > .extra-box {
  font-size: 14px;
  line-height: 24px;
  margin-top: 5px;
}

.plan.mobile > .column > .top-box > .extra-box > .tag {
  background-color: rgba(25, 204, 163, 0.1);
  border-radius: 5px;
  padding: 3px 7px;
}

.plan.mobile > .column > .top-box > .header-box,
.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box > .flex-box {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.plan.mobile > .column > .top-box > .header-box > .left-box,
.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box > .flex-box > .left-box {
  flex: 1;
}

.plan.mobile > .column > .top-box > .header-box > .left-box > .plan-name,
.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box > .flex-box > .left-box > .plan-name {
  font-size: 22px;
  line-height: 24px;
}

.plan.mobile > .column > .top-box > .header-box > .left-box > .plan-period,
.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box > .flex-box > .left-box > .plan-period {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  opacity: 0.6;
  text-transform: capitalize;
}

.plan.mobile > .column > .top-box > .header-box > .plan-price,
.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box > .flex-box > .plan-price {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  text-align: right;
}

.plan.mobile > .column > .top-box.highlight {
  background: #447BE0;
  color: #FFFFFF;
}

.plan.mobile > .column > .top-box.highlight > .button-box > .btn-action {
  color: #FFFFFF;
  border-color: #FFFFFF;
}

.plan.mobile > .column > .top-box.highlight > .header-box {
  position: relative;
}

.plan.mobile > .column > .top-box.highlight > .header-box .popular1 {
  background-color: #19CCA3;
  color: #FFFFFF;
  font-size: 14px;
  left: -43px;
  line-height: 20px;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: calc(100% + 43px);
}

.plan.mobile > .column > .top-box.highlight > .header-box .popular1.placeholder {
  position: unset;
}

.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box {
  flex: 1;
}

.plan.mobile > .column > .top-box.highlight > .header-box > .highlight-box > .flex-box {
  margin-top: 18px;
}

.p-centre-banner > .ax-centre-banner > .content-box > .title {
  margin: 0 32.2222% 13px 32.2222%;
}

.p-left-icon-card {
  margin-top: 40px;
}

.p-left-icon-card > .ax-left-icon-card {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.p-text > .additional-note {
  color: #808080;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin: 29px 0 76px 0;
  text-align: left;
}

.p-title1 {
  margin-top: 110px;
}

@media screen and (max-width: 1163px) {
  .plan {
    display: none;
  }

  .plan.mobile {
    display: grid;
    gap: 20px;
    grid-auto-columns: 1fr;
    /* grid-auto-rows: 1fr; */
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  }

  .plan.mobile .top-box{
    min-height: 185px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 639px) {
  .content {
    margin: 33px 7.2464% 0 7.2464%;
  }

  .p-left-icon-card {
    margin-top: 32px;
  }

  .p-text > .additional-note {
    margin: 30px 0 67px 0;
  }

  .p-title1 {
    margin-top: 40px;
  }

  .p-title1 > .ax-title > .main {
    color: #254476;
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 34px 7.7295%;
  }

  .p-title1 > .ax-title > .sub {
    margin: 0 7.2464%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1365px) {
  .content {
    margin: 20px 7.1875%;
  }
}
