.snbh-image-right-title-description-card {
  margin: 47px 14.375% 0 14.4444%;
}

.snbh-image-right-title-description-card > .ax-column-card-grid2 {
  gap: 34px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.snbh-image-right-title-description-card > .ax-column-card-grid2 > .item {
  border-bottom: solid 7px#47DBDB;
  padding: 20px 17px;
}

.snbh-image-right-title-description-card > .ax-column-card-grid2 > .item > .text-box {
  text-align: center;
}

.snbh-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box {
  margin: 0;
}

.snbh-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box > img {
  border-radius: 5px;
  width: 100%;
}

.snbh-left-content-image-card-block {
  margin: 155px 6.8056% 0 6.875%;
}

.snbh-left-right-content-block {
  margin-top: 182px;
}

.snbh-left-right-content-block > .ax-left-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.snbh-title {
  margin-top: 110px;
}

.snbh-title > .ax-title > .main {
  color: #234171;
  font-size: 40px;
  line-height: 46px;
}

.snr-title2 {
  margin-top: 40px;
}

.snr-title2 > .ax-title > .main {
  color: #254476;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 10px;
}

.snr-title2 > .ax-title > .sub {
  margin: 0 7.7295%;
}

.snr-title3 {
  margin-top: 38px;
}

.snr-title3 > .ax-title > .main {
  color: #254476;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 10px;
}

.snbh-two-column-full-width {
  margin-top: 100px;
}

@media screen and (max-width: 639px) {
  .snbh-image-right-title-description-card {
    margin: 38px 10.8695% 0 10.8695%;
  }

  .snbh-image-right-title-description-card > .ax-column-card-grid2 {
    grid-template-columns: 1fr;
  }

  .snbh-image-right-title-description-card > .ax-column-card-grid2 > .item {
    padding: 20px 17px;
  }

  .snbh-image-right-title-description-card > .ax-column-card-grid2 > .item > .text-box {
    text-align: center;
  }

  .snbh-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box {
    margin: 0;
  }

  .snbh-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box > img {
    border-radius: 5px;
    width: 100%;
  }

  .snbh-left-content-image-card-block {
    margin: 41px 7.7295% 0 7.7295%;
  }

  .snbh-left-right-content-block {
    margin-top: 40px;
  }

  .snbh-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.7295% 0 7.7295%;
  }

  .snbh-title {
    margin-top: 40px;
  }

  .snbh-title > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 34px 7.7295%;
  }

  .snbh-title > .ax-title > .sub {
    line-height: 24px;
    margin: 0 10.8695% 34px 10.8695%;
  }

  .snr-title2 {
    display: block;
  }

  .snr-title3 {
    display: block;
  }

  .snbh-two-column-full-width {
    margin-top: 41px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .snbh-image-right-title-description-card {
    margin: 47px 7.1875% 0 7.2222%;
  }

  .snbh-image-right-title-description-card1 > .ax-column-card-grid2 {
    grid-template-columns: 1fr 1fr;
  }

  .snbh-left-content-image-card-block {
    margin: 155px 3.4028% 0 3.4375%;
  }

  .snbh-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }
}
