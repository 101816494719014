.ax-left-title-two-card {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.ax-left-title-two-card > .cards {
  display: flex;
  flex: 1;
  justify-content: center;
}

.ax-left-title-two-card > .cards > .card {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 21px;
  max-width: 325px;
  min-width: 162.5px;
}

.ax-left-title-two-card > .cards > .card > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  padding: 30px;
}

.ax-left-title-two-card > .cards > .card > .image-box > .img {
  border-radius: 10px 10px 0 0;
  height: auto;
  max-width: 100%;
  min-width: 5%;
}

.ax-left-title-two-card > .title {
  color: #234171;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  max-width: 260px;
  min-width: 130px;
  text-transform: capitalize;
}

@media screen and (max-width: 959px) {
  .ax-left-title-two-card > .cards {
    justify-content: flex-start;
  }

  .ax-left-title-two-card > .cards > .card {
    margin: 0;
  }

  .ax-left-title-two-card > .cards > .card:not(:last-child) {
    margin-right: 42px;
  }

  .ax-left-title-two-card > .title {
    margin-bottom: 40px;
    min-width: 100%;
  }
}

@media screen and (max-width: 639px) {
  .ax-left-title-two-card > .cards {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .ax-left-title-two-card > .cards > .card {
    margin: 0;
    max-width: 100%;
  }

  .ax-left-title-two-card > .cards > .card:not(:last-child) {
    margin: 0 0 25px 0;
  }
}
