.ax-borderless-column-card-grid {
  display: grid;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  gap: 60px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: minmax(auto, 300px) minmax(auto, 300px) minmax(auto, 300px);
  justify-content: center;
}

.ax-borderless-column-card-grid .card-box {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.ax-borderless-column-card-grid .card-box > .image-box {
  margin-bottom: 30px;
}

.ax-borderless-column-card-grid .card-box > .image-box > .img {
  height: auto;
  max-width: 100%;
}

@media screen and (max-width: 639px) {
  .ax-borderless-column-card-grid {
    grid-template-columns: 1fr;
  }

  .ax-borderless-column-card-grid .card-box > .image-box {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 640px) and (max-width: 780px) {
  .ax-borderless-column-card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 781px) and (max-width: 1023px) {
  .ax-borderless-column-card-grid {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
  }
}
