
.logos-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3em;
}

.logos-holder > .logos-content {
    height: 100px;
    width: auto;

}

.logo-fineprint {
    text-align: center;
    display: block;
    margin-top: 2em !important;
    font-size: .7rem !important;
}

.logo-description {
    margin-top: 2em;
}