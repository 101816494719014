$color-primary-light: #4378df;
$color-primary-dark: #254476;
$color-secondary: #46cfdc;
$color-tertiary: #516997;

$colors: (
  primary-light: $color-primary-light,
  primary-dark: $color-primary-dark,
  secondary: $color-secondary,
  tertiary: $color-tertiary,
);
