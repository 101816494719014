.ax-disclaimer {
  color: #808080;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.ax-disclaimer > .text {
  font-size: 14px;
  line-height: 24px;
}

.ax-disclaimer > .text > .title {
  font-weight: bold;
}
