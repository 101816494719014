@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.cu-rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.cu-box {
  border: 0.5px solid #DBDBDB;
  border-radius: 5px;
  margin: 50px 14.3056% 90px 14.4444%;
  padding: 58px;
}

.cu-box > .button-box {
  margin-top: 49px;
  text-align: center;
}

.cu-box > .cu-form-grid {
  align-items: flex-start;
  display: grid;
  gap: 40px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 16px 36px -16px;
}

.cu-box > .cu-form-grid.col2 {
  grid-template-columns: 1fr 1fr;
}

.cu-success-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 108px 0 128px 0;
  text-align: center;
}

.cu-success-box > .image-box {
  margin-bottom: 40px;
}

.cu-success-box > .image-box > .img-success {
  height: auto;
  width: 371px;
}

.cu-success-box > .title-box > .title {
  color: #254476;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  margin-bottom: 8px;
}

.cu-success-box > .title-box > .title > .highlight {
  color: #4378DF;
}

.cu-success-box > .title-box > .subtitle {
  color: #506A96;
  font-size: 16px;
  line-height: 19px;
}

.cu-title {
  margin: 110px 14.3056% 0 14.4444%;
}

@media screen and (max-width: 959px) {
  .cu-box > .cu-form-grid.col2 {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 639px) {
  .cu-box {
    margin: 36px 7.7295% 38px 7.7295%;
    padding: 29px 21px;
  }

  .cu-box > .button-box {
    margin-top: 20px;
    text-align: center;
  }

  .cu-box > .button-box > .MuiButton-root {
    width: 100%;
  }

  .cu-box > .cu-form-grid {
    row-gap: 13px;
    margin-bottom: 13px;
  }

  .cu-title {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .cu-title > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 36px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .cu-box {
    margin: 50px 7.1528% 90px 7.2222%;
  }

  .cu-title {
    margin: 110px 7.1528% 0 7.2222%;
  }
}
