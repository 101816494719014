.ax-footer {
  background-color: #102F5B;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.ax-footer > .content-box {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  padding: 76px 14.4444% 108px 14.4444%;
}

.ax-footer > .content-box > .left-box {
  margin-right: 6.9444%;
  width: 190px;
}

.ax-footer > .content-box > .left-box > .description {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 15px;
  opacity: 0.7;
}

.ax-footer > .content-box > .left-box > .icons {
  align-items: center;
  display: flex;
}

.ax-footer > .content-box > .left-box > .icons > .icon-box {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ax-footer > .content-box > .left-box > .icons > .icon-box:not(:last-child) {
  margin-right: 10px;
}

.ax-footer > .content-box > .left-box > .icons > .icon-box > a {
  height: 24px;
  width: 24px;
}

.ax-footer > .content-box > .left-box > .icons > .icon-box > a .img {
  height: auto;
  width: 100%;
}

.ax-footer > .content-box > .left-box > .logo-box {
  align-items: center;
  display: flex;
  margin-bottom: 14px;
}

.ax-footer > .content-box > .left-box > .logo-box > .img {
  max-width: 58px;
}

.logo-box .img img{
  max-width: 58px;
}

.ax-footer > .content-box > .left-box > .title {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 12px;
}

.ax-footer > .content-box > .right-box {
  align-items: stretch;
  display: flex;
  flex: 1;
}

.ax-footer > .content-box > .right-box.mobile {
  display: none;
}

.ax-footer > .content-box > .right-box > .link-box {
  flex: 1;
}

.ax-footer > .content-box > .right-box > .link-box:not(:last-child) {
  padding-right: 40px;
}

.ax-footer > .content-box > .right-box > .link-box > .links > .link {
  margin-bottom: 20px;
}

.ax-footer > .content-box > .right-box > .link-box > .links > .link > a {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  text-decoration-color: #FFFFFF;
}

.ax-footer > .content-box > .right-box > .link-box > .title {
  align-items: stretch;
  color: #46CFDC;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  line-height: 16px;
  margin-bottom: 23px;
}

.ax-footer > .content-box > .right-box > .link-box > .title > .indicator {
  display: none;
}

.ax-footer > .copyright {
  background-color: #07234A;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.5;
  padding: 22px 0;
  text-align: center;
}

.ax-footer.margin-bottom {
  margin-bottom: 60px;
}

@media screen and (max-width: 1341px) {
  .ax-footer.margin-bottom {
    margin-bottom: 76px;
  }
}

@media screen and (max-width: 1287px) {
  .ax-footer.margin-bottom {
    margin-bottom: 92px;
  }
}

@media screen and (max-width: 1064px) {
  .ax-footer.margin-bottom {
    margin-bottom: 105px;
  }
}

@media screen and (max-width: 868px) {
  .ax-footer.margin-bottom {
    margin-bottom: 121px;
  }
}

@media screen and (max-width: 824px) {
  .ax-footer.margin-bottom {
    margin-bottom: 137px;
  }
}

@media screen and (max-width: 469px) {
  .ax-footer.margin-bottom {
    margin-bottom: 153px;
  }
}

@media screen and (max-width: 418px) {
  .ax-footer.margin-bottom {
    margin-bottom: 169px;
  }
}

@media screen and (max-width: 300px) {
  .ax-footer.margin-bottom {
    margin-bottom: 185px;
  }
}

@media screen and (max-width: 390px) {
  .ax-footer.margin-bottom {
    margin-bottom: 201px;
  }
}

@media screen and (max-width: 256px) {
  .ax-footer.margin-bottom {
    margin-bottom: 217px;
  }
}

@media screen and (max-width: 249px) {
  .ax-footer.margin-bottom {
    margin-bottom: 278px;
  }
}

@media screen and (max-width: 227px) {
  .ax-footer.margin-bottom {
    margin-bottom: 262px;
  }
}

@media screen and (max-width: 204px) {
  .ax-footer.margin-bottom {
    margin-bottom: 294px;
  }
}

@media screen and (max-width: 639px) {
  .ax-footer > .content-box {
    padding: 35px 7.2464% 10px 7.2464%;
  }

  .ax-footer > .content-box > .right-box {
    display: none;
  }

  .ax-footer > .content-box > .right-box.mobile {
    display: flex;
    flex-wrap: wrap;
  }

  .ax-footer > .content-box > .right-box > .link-box {
    min-width: 100%;
    margin-bottom: 30px;
  }

  .ax-footer > .content-box > .right-box > .link-box:not(:last-child) {
    padding: 0;
  }

  .ax-footer > .content-box > .right-box > .link-box > .links {
    display: none;
    margin-top: 23px;
  }

  .ax-footer > .content-box > .right-box > .link-box > .links.expand {
    display: block;
  }

  .ax-footer > .content-box > .right-box > .link-box > .title {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  .ax-footer > .content-box > .right-box > .link-box > .title:hover {
    color: #EBEBEB;
    cursor: pointer;
  }

  .ax-footer > .content-box > .right-box > .link-box > .title > .indicator {
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-height: 599px) {
  .ax-footer > .content-box {
    padding: 38px 14.4444% 54px 14.4444%;
  }
}

@media screen and (max-width: 1023px) {
  .ax-footer > .content-box > .left-box {
    margin: 0;
    min-width: 100%;
  }

  .ax-footer > .content-box > .right-box {
    margin-top: 30px;
    min-width: 100%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1279px) {
  .ax-footer > .content-box > .right-box > .link-box:not(:last-child) {
    padding-right: 20px;
  }
}
