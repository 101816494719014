.hp-banner-left-text {
  margin: 0 14.4444% 0 14.375%;
}

.hp-centre-banner {
  margin-top: 110px;
}

.hp-column-card-grid {
  margin: 137px 14.375% 0 14.375%;
}

.hp-cookie-consent {
  background-color: #102F5B;
  bottom: 0;
  color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  padding: 14px 14.4444% 14px 14.5139%;
  position: fixed;
  width: 71.0417%;
  z-index: 3;
}

.hp-cookie-consent > .button-box {
  width: 210px;
}

.hp-cookie-consent > .button-box > .MuiButton-root.MuiButton-contained,
.hp-main-banner > .button-box > .MuiButton-root.MuiButton-contained {
  border-radius: 5px;
  color: #FFFFFF;
}

.hp-cookie-consent > .button-box > .MuiButton-root.MuiButton-contained {
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  height: 30px;
}

.hp-cookie-consent > .button-box > .btn-settings {
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 9px;
  min-width: 114px;
  text-transform: capitalize;
}

.hp-cookie-consent > .button-box > .btn-settings:hover {
  background-color: rgba(255, 255, 255, 0.27);
}

.hp-cookie-consent > .button-box > .btn-action {
  background-image: linear-gradient(90deg, #46D3DC 0%, #4486DD 100%);
  min-width: 78px;
  text-transform: capitalize;
}

.hp-cookie-consent > .button-box > .btn-action:hover {
  background-image: linear-gradient(90deg, #46D3DC 0%, #2671D3 100%);
}

.hp-cookie-consent > .text-box {
  flex: 1;
}

.hp-cookie-consent > .text-box > a.link {
  color: #FFFFFF;
  font-weight: 900;
  text-decoration: none;
  text-decoration-color: #FFFFFF;
  border-bottom: #FFFFFF 0.125em solid;
}

.hp-cookie-consent > .text-box > a.link:hover {
  color: #EBEBEB;
}

.hp-cookie-consent > .text-box > a.link:active {
  color: #EBEBEB;
}

.hp-cookie-consent > .text-box > a.link:visited {
  color: #EBEBEB;
}

.hp-img-aic-service {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.hp-img-aic-service > .img {
  height: auto;
  width: 210px;
}

.hp-img-join {
  height: auto;
  width: 100%;
}

.hp-img-join-banner {
  height: fit-content;
  width: fit-content;
}

.hp-left-content-card-block {
  margin: 70px 10.3472% 0 14.4444%;
}

.hp-main-banner {
  display: flex;
  font-family: Roboto;
  font-style: normal;
  margin: 108px 10.4861% 119px 14.4444%;
}

.hp-main-banner > .image-box {
  max-height: 453px;
  max-width: 667px;
}

.hp-main-banner > .image-box > .img-banner {
  height: auto;
  width: 100%;
}

.hp-main-banner > .text-box > .btn-action {
  background-image: linear-gradient(90deg, #46D3DC 0%, #4382DF 100%);
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  height: 50px;
}

.hp-main-banner > .text-box > .btn-action:hover {
  background-image: linear-gradient(90deg, #46D3DC 0%, #246CD6 100%);
}

.hp-main-banner > .text-box > .subtitle {
  color: #4F6A97;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 17px;
}

.hp-main-banner > .text-box > .title {
  color: #254576;
  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  margin-bottom: 14px;
}

.hp-right-content-card-block {
  margin: 109px 14.5139% 0 10.2778%;
}

.hp-title1 {
  background-color: #FFFFFF;
  border-radius: 20px 20px 0px 0px;
  margin-top: -52px;
  padding: 127px 0 36px 0;
}

.hp-title2 {
  margin-top: 174px;
}

.hp-title3 {
  margin: 174px 24.9306% 0 24.8611%;
}

.hp-title4.MuiTypography-root {
  color: #254576;
  font-family: Roboto;
  font-style: normal;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  margin: 104px auto 39px auto;
  max-width: 792px;
  text-align: center;
}

.hp-two-column-social {
  margin: 141px 14.4444% 135px 14.4444%;
}

.hp-two-column-social > .grid-box {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 29px 33px;
  grid-template-columns: minmax(auto, 500px) minmax(auto, 500px);
}

.hp-two-column-social > .grid-box > .card.MuiPaper-root {
  border-radius: 10px;
  color: #FFFFFF;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  min-height: 260px;
  padding: 40px;
  box-sizing: border-box;
}

.hp-two-column-social > .grid-box > .card.left > div > .btn-action {
  background-color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  color: #4378DF;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.hp-two-column-social > .grid-box > .card.left > div > .btn-action:hover {
  background-color: #EBEBEB;
}

.hp-two-column-social > .grid-box > .card.left > div > .description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 17px;
  max-width: 269px;
}

.hp-two-column-social > .grid-box > .card.left > div > .title {
  font-size: 25px;
  line-height: 28px;
  max-width: 368px;
}

.hp-two-column-social > .grid-box > .card.right > div > .btn-action {
  background-color: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.hp-two-column-social > .grid-box > .card.right > div > .btn-action:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.hp-two-column-social > .grid-box > .card.right > div > .description {
  font-size: 16px;
  margin-bottom: 15px;
  max-width: 269px;
}

.hp-two-column-social > .grid-box > .card.right > div > .title {
  font-size: 25px;
  margin-bottom: 15px;
  max-width: 284px;
}

.hp-two-column-card-with-button {
  margin: 51px 14.4444% 0 14.4444%;
}

.hp-video-box {
  background-color: #4276DB;
  border-radius: 20px 20px 0px 0px;
  color: #FFFFFF;
  font-family: Roboto;
  margin-top: 137px;
  padding: 120px 14.4444% 202px 14.5139%;
}

.hp-video-box > .flex-box {
  display: flex;
  flex-wrap: wrap;
}

.hp-video-box > .flex-box > .left-box {
  flex: 1;
  margin-right: 83px;
  max-width: 460px;
}

.hp-video-box > .flex-box > .left-box > .card > .description {
  color: #4276DB;
  font-family: Roboto;
  font-style: normal;
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  padding: 17px 49px;
  text-align: center;
}

.hp-video-box > .flex-box > .left-box > .list {
  margin-bottom: 20px;
}

.hp-video-box > .flex-box > .left-box > .list > .item {
  display: flex;
}

.hp-video-box > .flex-box > .left-box > .list > .item:not(:last-child) {
  margin-bottom: 12px;
}

.hp-video-box > .flex-box > .left-box > .list > .item > .icon {
  margin-right: 15px;
}

.hp-video-box > .flex-box > .left-box > .list > .item > .icon > .img {
  height: 24px;
  width: 24px;
}

.hp-video-box > .flex-box > .left-box > .title-left {
  font-size: 25px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: 25px;
}

.hp-video-box > .flex-box > .right-box {
  flex: 1;
}

.hp-video-box > .flex-box > .right-box > .react-player iframe {
  border-radius: 5px;
}

.hp-video-box > .flex-box > .right-box > .title-right {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 25px;
}

@media screen and (max-width: 377px) {
  .hp-centre-banner > .ax-centre-banner > .content-box > div .button-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .hp-centre-banner > .ax-centre-banner > .content-box > div .button-box > .btn-action {
    margin: 0;
  }

  .hp-centre-banner > .ax-centre-banner > .content-box > div .button-box > .btn-action:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 639px) {
  .hp-banner-left-text {
    margin: 29px 7.2464% 0 7.2464%;
  }

  .hp-centre-banner {
    margin-top: 45px;
  }

  .hp-centre-banner > .ax-centre-banner > .content-box > div {
    padding: 0 7.7295%;
  }

  .hp-column-card-grid {
    margin: 50px 7.2464% 0 7.2464%;
  }

  .hp-cookie-consent {
    flex-wrap: wrap;
    padding: 14px 7.2464% 14px 7.2464%;
    width: 85.5072%;
  }

  .hp-cookie-consent > .button-box {
    margin-top: 15px;
  }

  .hp-cookie-consent > .text-box {
    min-width: 100%;
  }

  .hp-image-block {
    margin-top: 30px;
  }

  .hp-img-aic-service {
    margin-top: 30px;
  }

  .hp-img-aic-service > .img {
    height: auto;
    width: 121px;
  }

  .hp-left-content-card-block {
    margin: 30px 7.2464% 0 7.2464%;
  }

  .hp-right-content-card-block {
    margin: 30px 7.2464% 0 7.2464%;
  }

  .hp-two-column-social {
    margin: 47px 7.2464% 58px 7.2464%;
  }

  .hp-two-column-social > .grid-box {
    grid-template-columns: minmax(auto, 354px);
    justify-content: center;
  }

  .hp-title1 {
    background-color: #FFFFFF;
    border-radius: 0;
    margin: 77px 7.2464% 0 7.2464%;
    padding: 0;
  }

  .hp-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .hp-title2 {
    margin: 70px 7.2464% 0 7.2464%;
  }

  .hp-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .hp-title3 {
    margin: 50px 2.3611% 0 2.0833%;
  }

  .hp-title3 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .hp-title4.MuiTypography-root {
    font-size: 30px;
    line-height: 35px;
    margin: 49px 7.2464% 44px 7.2464%;
  }

  .hp-two-column-card-with-button {
    margin: 30px 7.2464% 0 7.2464%;
  }

  .hp-video-box {
    background-color: #4276DB;
    border-radius: 20px 20px 0px 0px;
    color: #FFFFFF;
    font-family: Roboto;
    margin-top: 20px;
    padding: 54px 7.7295% 60px 7.7295%;
  }

  .hp-video-box > .flex-box > .left-box {
    margin-right: 0;
    min-width: 100%;
  }

  .hp-video-box > .flex-box > .left-box > .card {
    margin-bottom: 54px;
  }

  .hp-video-box > .flex-box > .left-box > .card > .description {
    padding: 15px;
  }

  .hp-video-box > .flex-box > .left-box > .list {
    margin-bottom: 40px;
  }

  .hp-video-box > .flex-box > .right-box > .react-player {
    height: 211px !important;
  }
}

@media screen and (max-width: 280px) {
  .hp-cookie-consent {
    width: calc(100vw - 14.4928%);
  }
}

@media screen and (max-width: 249px) {
  .hp-cookie-consent > .button-box > .MuiButton-root {
    width: 100%;
  }

  .hp-cookie-consent > .button-box > .MuiButton-root:not(:last-child) {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 640px) and (max-width: 959px) {
  .hp-two-column-social > .grid-box {
    grid-template-columns: minmax(auto, 500px);
    justify-content: center;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .hp-banner-left-text {
    margin: 0 7.2222% 0 7.1875%;
  }

  .hp-column-card-grid {
    margin: 137px 7.1875% 0 7.1875%;
  }

  .hp-cookie-consent {
    flex-wrap: wrap;
    padding: 14px 7.2222% 14px 7.2569%;
    width: 85.5209%;
  }

  .hp-cookie-consent > .button-box {
    margin-top: 15px;
  }

  .hp-cookie-consent > .text-box {
    min-width: 100%;
  }

  .hp-left-content-card-block {
    margin: 70px 5.1736% 0 7.2222%;
  }

  .hp-main-banner {
    margin: 108px 5.2431% 119px 7.2222%;
  }

  .hp-right-content-card-block {
    margin: 109px 7.2569% 0 5.1389%;
  }

  .hp-title3 {
    margin: 174px 12.4653% 0 12.4306%;
  }

  .hp-title4.MuiTypography-root {
    color: #254576;
    font-family: Roboto;
    font-style: normal;
    font-size: 40px;
    font-weight: bold;
    line-height: 47px;
    margin: 104px auto 39px auto;
    max-width: 792px;
    text-align: center;
  }

  .hp-two-column-social {
    margin: 141px 7.2222% 135px 7.2222%;
  }

  .hp-two-column-card-with-button {
    margin: 51px 7.2222% 0 7.2222%;
  }

  .hp-video-box {
    padding: 120px 7.2222% 202px 7.2569%;
  }

  .hp-video-box > .flex-box > .left-box {
    margin-right: 41px;
  }
}

@media screen and (max-width: 767px) {
  .hp-main-banner {
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0 59px 0;
  }

  .hp-main-banner > .image-box {
    margin: 0 2.4155% 0 1.6908%;
    max-height: 453px;
    max-width: 667px;
  }

  .hp-main-banner > .image-box > .img-banner {
    height: auto;
    width: 100%;
  }

  .hp-main-banner > .text-box {
    text-align: center;
    margin: 0 8.2126% 40px 7.2464%;
  }

  .hp-main-banner > .text-box > .btn-action {
    height: 50px;
    max-width: 300px;
    width: 100%;
  }

  .hp-main-banner > .text-box > .btn-action:hover {
    background-image: linear-gradient(90deg, #46D3DC 0%, #246CD6 100%);
  }

  .hp-main-banner > .text-box > .subtitle {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  .hp-main-banner > .text-box > .title {
    font-size: 40px;
    line-height: 47px;
  }
}

@media screen and (min-width: 640px) and (max-width: 799px) {
  .hp-video-box > .flex-box > .left-box {
    margin-right: 0;
    min-width: 100%;
  }

  .hp-video-box > .flex-box > .left-box > .card {
    margin-bottom: 54px;
  }

  .hp-video-box > .flex-box > .left-box > .list {
    margin-bottom: 40px;
  }

  .hp-video-box > .flex-box > .right-box > .react-player {
    height: 360px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
