.ax-steps {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 0 14.4444%;
  text-align: center;
}

.ax-steps > .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ax-steps > .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ax-steps > .box > .line {
  border-bottom: 1px solid #C3DAFF;
  height: 0px;
  margin-top: 50px;
  margin-left: 100px;
  position: absolute;
  width: calc(71.1111% - 200px);
  z-index: 1;
}

.ax-steps > .box > .step {
  width: 200px;
  z-index: 99;
}

.ax-steps > .box > .step .eclipse {
  align-items: center;
  background: linear-gradient(180deg, #3160BC 0%, rgba(67, 120, 223, 0.4) 300%);
  border: 11px solid #ECF1FC;
  border-radius: 50%;
  color: #FFFFFF;
  display: flex;
  font-size: 36px;
  height: 78px;
  justify-content: center;
  line-height: 36px;
  margin: 0 auto;
  width: 78px;
}

.ax-steps > .box > .step .title {
  color: #4378DF;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  margin-top: 26px;
}

.ax-steps > .box > .step .description {
  color: #506A96;
  font-size: 16px;
  line-height: 22px;
}

.ax-steps > .box.mobile {
  display: none;
}

.ax-steps > .box.mobile > .step {
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
  min-height: 120px;
  width: 100%;
  z-index: 99;
}

.ax-steps > .box.mobile > .step > .left {
  margin-right: 25px;
}

.ax-steps > .box.mobile > .step > .left > .line {
  border-left: 1px solid #C3DAFF;
  height: 100%;
  margin: 0 auto;
  width: 0px;
  z-index: -1;
}

.ax-steps > .box.mobile > .step > .right {
  flex: 1;
  text-align: left;
}

.ax-steps > .box.mobile > .step > .right > .title {
  margin-top: 0;
}

@media screen and (max-width: 1064px) {
  .ax-steps {
    margin: 0 7.2222%;
  }

  .ax-steps > .box > .line {
    width: calc(85.5556% - 200px);
  }
}

@media screen and (max-width: 959px) {
  .ax-steps {
    margin: 0 7.2222%;
  }

  .ax-steps > .box {
    display: none;
  }

  .ax-steps > .box.mobile {
    display: block;
  }
}

@media screen and (max-width: 639px) {
  .ax-steps {
    margin: 0 7.2464%;
  }

  .ax-steps > .box > .step .eclipse {
    border: 8px solid #ECF1FC;
    font-size: 24px;
    height: 60px;
    line-height: 24px;
    width: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .ax-steps > .box > .line {
    width: 824px;
  }
}
