@font-face {
  font-family: 'Roboto';
  src: local('RobotoThinItalic'), url(./fonts/Roboto-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoLightItalic'), url(./fonts/Roboto-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoItalic'), url(./fonts/Roboto-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoMediumItalic'), url(./fonts/Roboto-MediumItalic.ttf) format('truetype');
  font-weight: 500;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoBoldItalic'), url(./fonts/Roboto-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoBlackItalic'), url(./fonts/Roboto-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoThin'), url(./fonts/Roboto-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoLight'), url(./fonts/Roboto-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoMedium'), url(./fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoBold'), url(./fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('RobotoBlack'), url(./fonts/Roboto-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('LatoThinItalic'), url(./fonts/Lato-ThinItalic.ttf) format('truetype');
  font-weight: 100;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoLightItalic'), url(./fonts/Lato-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoItalic'), url(./fonts/Lato-Italic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoBoldItalic'), url(./fonts/Lato-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoBlackItalic'), url(./fonts/Lato-BlackItalic.ttf) format('truetype');
  font-weight: 900;
  font-style: italic, oblique;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoThin'), url(./fonts/Lato-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoLight'), url(./fonts/Lato-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoBold'), url(./fonts/Lato-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: local('LatoBlack'), url(./fonts/Lato-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

html,
body {
  background-image: linear-gradient(0deg, #2e5696 0%, #638ee1 100%);
  margin: 0;
  min-height: 100vh;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root {
  background-color: #FFFFFF;
  margin: 0 auto;
  max-width: 1440px;
}
