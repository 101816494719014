.ax-banner-left-text {
  display: flex;
  align-items: center;
  min-height: 270px;
}

.ax-banner-left-text > .text-box {
  color: #FFFFFF;
  font-family: Roboto;
  font-style: normal;
}

.ax-banner-left-text > .text-box > .description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
  max-width: 405px;
}

.ax-banner-left-text > .text-box > .description > a {
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
}

.ax-banner-left-text > .text-box > .subtitle {
  font-size: 16px;
  line-height: 24px;
  max-width: 405px;
}

.ax-banner-left-text > .text-box > .title {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 10px;
  max-width: 257px;
}

@media screen and (max-width: 639px) {
  .ax-banner-left-text {
    align-items: flex-start;

  }

  .ax-banner-left-text > .text-box {
    margin-left: 0;
  }
}
