.ax-two-column-full-width {
  color: #FFFFFF;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  position: relative;
  text-align: center;
}

.ax-two-column-full-width > .card-box {
  align-items: stretch;
  display: flex;
}

.ax-two-column-full-width > .card-box > .card {
  flex: 1;
  width: 100%;
}

.ax-two-column-full-width > .card-box > .card > .box {
  height: calc(100% - 222px);
  padding: 111px 31.5972%;
}

.ax-two-column-full-width > .card-box > .card > .box > .btn-action {
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}

.ax-two-column-full-width > .card-box > .card > .box > .description {
  font-size: 16px;
  height: 76px;
  line-height: 19px;
  margin-bottom: 32px;
  overflow-y: hidden;
}

.ax-two-column-full-width > .card-box > .card > .box > .description > a {
  color: #FFFFFF;
}

.ax-two-column-full-width > .card-box > .card > .box > .description > a:hover {
  color: #EBEBEB;
}

.ax-two-column-full-width > .card-box > .card > .box > .description > a:active {
  color: #EBEBEB;
}

.ax-two-column-full-width > .card-box > .card > .box > .description > a:visited {
  color: #EBEBEB;
}

.ax-two-column-full-width > .card-box > .card > .box > .img {
  height: 53px;
  margin-bottom: 24px;
  width: 53px;
}

.ax-two-column-full-width > .card-box > .card > .box.even {
  background-image: linear-gradient(180deg, #235DCE 0%, rgba(66, 119, 221, 0.5) 166.99%, rgba(66, 119, 221, 0) 369.61%);
}

.ax-two-column-full-width > .card-box > .card > .box.even > .btn-action {
  background-color: #FFFFFF;
  border: 2px solid #FFFFFF;
  color: #4378DF;
}

.ax-two-column-full-width > .card-box > .card > .box.even > .btn-action:hover {
  background-color: #EBEBEB;
  border: 2px solid #EBEBEB;
  color: #2461D6;
}

.ax-two-column-full-width > .card-box > .card > .box.odd {
  background-image: linear-gradient(180deg, #0B2851 24.49%, rgba(11, 40, 81, 0) 229.48%);
}

.ax-two-column-full-width > .card-box > .card > .box.odd > .btn-action {
  background-color: transparent;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
}

.ax-two-column-full-width > .card-box > .card > .box.odd > .btn-action:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid #EBEBEB;
  color: #EBEBEB;
}

.ax-two-column-full-width > .overlay {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ax-two-column-full-width > .overlay > .text {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
  border-radius: 5px;
  color: #000000;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 639px) {
  .ax-two-column-full-width > .card-box {
    align-items: flex-start;
    flex-direction: column;
  }

  .ax-two-column-full-width > .card-box > .card > .box {
    height: calc(100% - 128px);
    min-height: 215px;
    padding: 64px 13.5266%;
  }
}

@media screen and (min-width: 640px) and (max-width: 994px) {
  .ax-two-column-full-width > .card-box > .card > .box {
    height: calc(100% - 110px);
    padding: 55px 15.7986%;
  }
}
