.po-centre-banner {
  margin-top: 103px;
}

.po-column-card-grid {
  margin: 39px 14.3056% 0 14.4444%;
}

.po-left-right-content-block {
  margin: 70px 14.4444% 0 14.375%;
}

.po-link {
  margin-top: 70px;
}

.po-link > .link {
  align-items: flex-start;
  color: #2F6BCC;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
}

.po-link > .link .img {
  height: 24px;
  width: 24px;
}

.po-title {
  margin-top: 107px;
}

.po-logos {
  margin-top: 107px;
}

@media screen and (max-width: 377px) {
  .po-centre-banner > .ax-centre-banner > .content-box > div .button-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .po-centre-banner > .ax-centre-banner > .content-box > div .button-box > .btn-action {
    margin: 0;
  }

  .po-centre-banner > .ax-centre-banner > .content-box > div .button-box > .btn-action:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 639px) {
  .po-centre-banner {
    margin-top: 67px;
  }

  .po-centre-banner > .ax-centre-banner > .content-box > div {
    padding: 0 7.7295%;
  }

  .po-column-card-grid {
    margin: 40px 10.8696% 0 10.8696%;
  }

  .po-left-right-content-block {
    margin: 57px 7.7295% 0 7.7295%;
  }

  .po-link {
    margin: 54px 0 67px 0;
  }

  .po-title {
    margin-top: 40px;
  }

  .po-title > .ax-title > .main {
    color: #254576;
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 10px 7.7295%;
  }

  .po-title > .ax-title > .sub {
    color: #506A96;
    font-size: 16px;
    line-height: 19px;
    margin: 0 7.7295% 0 7.7295%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .po-column-card-grid {
    margin: 39px 7.1528% 0 7.2222%;
  }
}
