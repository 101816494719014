.ax-left-content-button-card {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  justify-content: space-between;
  padding: 35px 66px;
}

.ax-left-content-button-card > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 959px) {

}

@media screen and (min-width: 960px) and (max-width: 1029px) {

}

@media screen and (min-width: 1030px) and (max-width: 1129px) {

}

@media screen and (min-width: 1130px) and (max-width: 1279px) {

}
