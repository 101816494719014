.sac-image-title-description-card {
  margin: 25px 12.8472% 0 12.7778%;
}

.sac-image-right-title-description-card {
  margin: 50px 14.4444% 0 14.4444%;
}

.sac-note {
  margin: 28px 14.5139% 90px 14.4444%;
}

.sac-note > .text {
  color: #808080;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  text-transform: capitalize;
}

.sac-right-content-block {
  margin-top: 143px;
}

.sac-right-content-block > .ax-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.sac-title1 {
  margin-top: 190px;
}

.sac-title1 > .ax-title > .main {
  color: #234171;
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 0;
}

.sac-title2 {
  margin-top: 92px;
}

.sac-title2 > .ax-title > .main {
  color: #234171;
  font-size: 40px;
  line-height: 46px;
}

@media screen and (max-width: 639px) {
  .sac-image-title-description-card {
    margin: 39px 10.8696% 0 10.8696%;
  }

  .sac-image-title-description-card > .ax-column-card-flex > .item {
    margin: 0;
    width: 100%;
  }

  .sac-image-title-description-card > .ax-column-card-flex > .item:not(:last-child) {
    margin-bottom: 25px;
  }

  .sac-image-right-title-description-card {
    margin: 31px 7.2464% 0 7.2464%;
  }

  .sac-image-right-title-description-card > .ax-column-card-grid2 {
    grid-template-columns: 1fr;
  }

  .sac-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box {
    flex-wrap: wrap;
    margin-bottom: 13px;
  }

  .sac-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box {
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-right: 0;
    min-width: 100%;
  }

  .sac-note {
    margin: 13px 7.7295% 40px 7.7295%;
  }

  .sac-right-content-block {
    margin-top: 40px;
  }

  .sac-right-content-block > .ax-right-content-block {
    flex-wrap: wrap-reverse;
    margin: 0 7.2464%;
  }

  .sac-right-content-block > .ax-right-content-block > .image-box {
    margin-bottom: 0;
  }

  .sac-right-content-block > .ax-right-content-block > .text-box {
    margin-bottom: 27px;
    text-align: center;
  }

  .sac-right-content-block > .ax-right-content-block > .text-box > .title {
    color: #234171;
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 34px 7.7295%;
  }

  .sac-title1 {
    margin-top: 39px;
  }

  .sac-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295%;
  }

  .sac-title2 {
    margin-top: 50px;
  }

  .sac-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 10.8696%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .sac-image-title-description-card {
    margin: 25px 6.4236% 0 6.3889%;
  }

  .sac-image-right-title-description-card {
    margin: 50px 7.2222% 0 7.2222%;
  }

  .sac-note {
    margin: 28px 7.2569% 90px 7.2222%;
  }

  .sac-right-content-block > .ax-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }
}
