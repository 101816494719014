.d-box {
  border: 0.5px solid #DBDBDB;
  border-radius: 5px;
  margin: 60px 14.3056% 90px 14.4444%;
  padding: 58px;
}

.d-box > .button-box {
  margin-top: 49px;
  text-align: center;
}

.d-box > .d-form-grid {
  align-items: flex-start;
  display: grid;
  gap: 40px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 16px 36px -16px;
}

.d-box > .d-form-grid.col2 {
  grid-template-columns: 1fr 1fr;
}

.d-box > .note {
  color: #808080;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.d-success-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 108px 0 128px 0;
  text-align: center;
}

.d-success-box > .button-box {
  margin-top: 32px;
}

.d-success-box > .button-box > .MuiButton-root {
  text-transform: capitalize;
}

.d-success-box > .button-box > .MuiButton-root:not(:last-child) {
  margin-right: 13px;
}

.d-success-box > .image-box {
  margin-bottom: 40px;
}

.d-success-box > .image-box > .img-success {
  height: auto;
  width: 371px;
}

.d-success-box > .title-box > .title {
  color: #254476;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  margin-bottom: 8px;
}

.d-success-box > .title-box > .title > .highlight {
  color: #4378DF;
}

.d-success-box > .title-box > .subtitle {
  color: #506A96;
  font-size: 16px;
  line-height: 19px;
}

.d-title {
  margin: 110px 25.4167% 0 25.4167%;
}

.d-title > .ax-title > .main {
  margin-bottom: 8px;
}

@media screen and (max-width: 959px) {
  .d-box > .d-form-grid.col2 {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 639px) {
  .d-box {
    margin: 33px 7.7295% 38px 7.7295%;
    padding: 29px 21px;
  }

  .d-box > .button-box {
    margin-top: 20px;
    text-align: center;
  }

  .d-box > .button-box > .MuiButton-root {
    width: 100%;
  }

  .d-box > .d-form-grid {
    row-gap: 13px;
    margin-bottom: 13px;
  }

  .d-title {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .d-title > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 31px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .d-box {
    margin: 50px 7.1528% 90px 7.2222%;
  }

  .d-title {
    margin: 110px 12.7083% 0 12.7083%;
  }
}
