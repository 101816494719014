.ax-right-content-card-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ax-right-content-card-block > .image-box {
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 47px;
}

.ax-right-content-card-block > .image-box > .img {
  height: auto;
  max-width: 500px;
  width: 100%;
}

.ax-right-content-card-block > .text-box {
  flex: 1;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.ax-right-content-card-block > .text-box > .list-box {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 50px 30px;
  grid-template-columns: minmax(auto, 275px) minmax(auto, 275px);
  justify-content: center;
}

.ax-right-content-card-block > .text-box > .list-box > .item > .top-box {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.ax-right-content-card-block > .text-box > .list-box > .item .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}

.ax-right-content-card-block > .text-box > .list-box > .item .img {
  height: auto;
  max-width: 39px;
}

.ax-right-content-card-block > .text-box > .list-box > .item .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  max-width: 198px;
}

.ax-right-content-card-block > .text-box > .title {
  background-color: #4378DF;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  max-width: 220px;
  margin-bottom: 30px;
  padding: 11px 0 10px 0;
  text-align: center;
  text-transform: capitalize;
}

@media screen and (max-width: 539px) {
  .ax-right-content-card-block > .text-box > .list-box {
    grid-gap: 0;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  .ax-right-content-card-block > .image-box {
    min-width: 100%;
  }

  .ax-right-content-card-block > .text-box {
    margin-top: 30px;
  }

  .ax-right-content-card-block > .text-box > .title {
    margin: 0 auto 30px auto;
  }

  .ax-right-content-card-block > .text-box > .list-box > .item > .top-box {
    margin-bottom: 10px;
  }

  .ax-right-content-card-block > .text-box > .list-box > .item .description {
    margin-bottom: 20px;
  }
}
