.ax-left-image-full-width {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ax-left-image-full-width > .image-box {
  flex: 1;
  margin-right: 60px;
}

.ax-left-image-full-width > .image-box > .img {
  height: auto;
  max-width: 703px;
}

.ax-left-image-full-width > .text-box {
  flex: 1;
}

.ax-left-image-full-width > .text-box > .description {
  color: #506A96;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  margin-right: 36.7069%;
}

.ax-left-image-full-width > .text-box > .list {
  color: #506A96;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
}

.ax-left-image-full-width > .text-box > .list > .item {
  align-items: stretch;
  display: flex;
}

.ax-left-image-full-width > .text-box > .list > .item > .image-box {
  align-items: center;
  display: flex;
  margin-right: 15px;
}

.ax-left-image-full-width > .text-box > .list > .item > .image-box > .img {
  height: 24px;
  width: 24px;
}

.ax-left-image-full-width > .text-box > .title {
  color: #234171;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin: 0 54.0785% 22px 0;
  text-transform: capitalize;
}


@media screen and (max-width: 1279px) {
  .ax-left-image-full-width > .image-box > .img {
    max-width: 633px;
  }
}

@media screen and (max-width: 1179px) {
  .ax-left-image-full-width > .image-box > .img {
    max-width: 527px;
  }
}

@media screen and (max-width: 959px) {
  .ax-left-image-full-width > .image-box {
    margin: 0 0 29px 0;
    min-width: 100%;
  }

  .ax-left-image-full-width > .image-box > .img {
    max-width: 100%;
  }

  .ax-left-image-full-width > .text-box > .title {
    margin: 0 0 22px 0;
  }

  .ax-left-image-full-width > .text-box > .description {
    margin-right: 0;
  }
}
