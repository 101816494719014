.ax-two-column-card-with-button {
  display: grid;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 29px 33px;
  grid-template-columns: minmax(auto, 490px) minmax(auto, 490px);
}

.ax-two-column-card-with-button > .card {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 30px 48px 40px;
}

.ax-two-column-card-with-button > .card > .btn-action {
  height: 41px;
  text-transform: capitalize;
}

.ax-two-column-card-with-button > .card > .description {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 22px;
}

.ax-two-column-card-with-button > .card > .top-box {
  align-items: center;
  display: flex;
}

.ax-two-column-card-with-button > .card > .top-box > .image-box {
  margin-right: 19px;
  max-height: 100px;
}

.ax-two-column-card-with-button > .card > .top-box > .image-box > .img {
  height: auto;
  width: 100px;
}

.ax-two-column-card-with-button > .card > .top-box > .text-box {
  color: #4378DF;
}

.ax-two-column-card-with-button > .card > .top-box > .text-box > .subtitle {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
}

.ax-two-column-card-with-button > .card > .top-box > .text-box > .title {
  font-size: 25px;
  line-height: 30px;
}

@media screen and (max-width: 860px) {
  .ax-two-column-card-with-button {
    grid-template-columns: 1fr;
  }

  .ax-two-column-card-with-button > .card > .btn-action {
    width: 100%;
  }

  .ax-two-column-card-with-button > .card > .description {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .ax-two-column-card-with-button > .card {
    padding: 30px 27px;
  }

  .ax-two-column-card-with-button > .card > .top-box {
    align-items: flex-start;
    margin-bottom: 6px;
  }

  .ax-two-column-card-with-button > .card > .top-box > .image-box {
    margin-right: 11px;
  }

  .ax-two-column-card-with-button > .card > .top-box > .image-box > .img {
    width: 70px;
  }

  .ax-two-column-card-with-button > .card > .top-box > .text-box > .subtitle {
    font-size: 23px;
  }
}
