.ax-image-title-list-two-column-card {
  align-items: stretch;
  color: #506A96;
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  justify-content: space-between;
}

.ax-image-title-list-two-column-card > .card {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex: 1;
  padding: 60px 42px 38px 42px;
}

.ax-image-title-list-two-column-card > .card:nth-child(even) {
  margin-left: 20.5px;
}

.ax-image-title-list-two-column-card > .card:nth-child(odd) {
  margin-right: 20.5px;
}

.ax-image-title-list-two-column-card > .card > .image-box {
  margin-bottom: 25px;
}

.ax-image-title-list-two-column-card > .card > .image-box > .img {
  height: auto;
  width: 72px;
}

.ax-image-title-list-two-column-card > .card > .text-box > .title {
  color: #506A96;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}

.ax-image-title-list-two-column-card > .card > .text-box > .list {
  color: #506A96;
  font-size: 16px;
  line-height: 20px;
}

.ax-image-title-list-two-column-card > .card > .text-box > .list > .item {
  align-items: stretch;
  display: flex;
  margin-bottom: 6px;
}

.ax-image-title-list-two-column-card > .card > .text-box > .list > .item > .image-box {
  margin-right: 15px;
}

.ax-image-title-list-two-column-card > .card > .text-box > .list > .item > .image-box > .img {
  height: 18px;
  width: 18px;
}

@media screen and (max-width: 767px) {
  .ax-image-title-list-two-column-card {
    display: block;
  }

  .ax-image-title-list-two-column-card > .card:not(:last-child) {
    margin-bottom: 31px;
  }

  .ax-image-title-list-two-column-card > .card:nth-child(even) {
    margin-left: 0;
  }

  .ax-image-title-list-two-column-card > .card:nth-child(odd) {
    margin-right: 0;
  }
}
