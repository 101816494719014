.ax-left-content-image-card-block {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  justify-content: space-between;
  padding: 75px 100px;
  align-items: center;
}

.ax-left-content-image-card-block > .image-box {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.ax-left-content-image-card-block > .image-box > .img {
  border-radius: 10px;
  height: auto;
  max-width: 400px;
  width: 100%;
}

.ax-left-content-image-card-block > .text-box {
  flex: 1;
  margin-right: 75px;
}

.ax-left-content-image-card-block > .text-box > .button-box {
  margin-top: 22px;
}

.ax-left-content-image-card-block > .text-box > .description > a {
  color: #2F6BCC;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
}

.ax-left-content-image-card-block > .text-box > .list-box {
  margin-top: 26px;
}

.ax-left-content-image-card-block > .text-box > .list-box > .item {
  display: flex;
}

.ax-left-content-image-card-block > .text-box > .list-box > .item:not(:last-child) {
  margin-bottom: 18px;
}

.ax-left-content-image-card-block > .text-box > .list-box > .item > .description {
  color: #506A96;
  font-size: 18px;
  line-height: 24px;
}

.ax-left-content-image-card-block > .text-box > .list-box > .item > .img {
  height: 23px;
  margin-right: 20px;
  width: 23px;
}

.ax-left-content-image-card-block > .text-box > .list-box > .item .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  max-width: 198px;
}

.ax-left-content-image-card-block > .text-box > .title {
  color: #254476;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 9px;
}

.ax-left-content-image-card-block > .text-box > .title > .span {
  color: #497fed;
}

@media screen and (max-width: 1064px) {
  .ax-left-content-image-card-block {
    padding: 37.5px 50px;
  }
}

@media screen and (max-width: 959px) {
  .ax-left-content-image-card-block > .text-box {
    margin-right: 37.5px;
  }
}

@media screen and (max-width: 899px) {
  .ax-left-content-image-card-block > .image-box {
    justify-content: flex-start;
    margin-top: 26px;
    min-width: 100%;
  }

  .ax-left-content-image-card-block > .text-box {
    margin-right: 0;
  }
}

@media screen and (max-width: 639px) {
  .ax-left-content-image-card-block {
    padding: 33px 23px;
  }
}
