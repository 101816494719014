.ax-right-list-box {
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.ax-right-list-box > .left-box > .box {
  margin-right: 85px;
}

.ax-right-list-box > .left-box > .box > .title {
  color: #487FED;
  font-size: 25px;
  font-weight: bold;
  line-height: 29px;
  text-transform: capitalize;
}

.ax-right-list-box > .left-box > .box > .img {
  height: 100px;
  margin-bottom: 10px;
  width: 100px;
}

.ax-right-list-box > .left-box > .box > .title {
  color: #487FED;
  font-size: 25px;
  font-weight: bold;
  line-height: 29px;
  max-width: 160px;
  text-transform: capitalize;
}

.ax-right-list-box > .right-box {
  flex: 1;
}

.ax-right-list-box > .right-box > .list-box {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr) );
}

.ax-right-list-box > .right-box > .list-box > .item > .box {
  display: flex;
  height: calc(100% - 18px);
  margin-bottom: 18px;
}

.ax-right-list-box > .right-box > .list-box > .item.bordered > .box {
  border-bottom: 1px solid #DBDBDB;
}

.ax-right-list-box > .right-box > .list-box > .item > .box > .img {
  height: 24px;
  margin-right: 20px;
  margin-bottom: 22px;
  width: 24px;
}

.ax-right-list-box > .right-box > .list-box > .item > .box > .text {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 639px) {
  .ax-right-list-box > .left-box {
    margin-bottom: 20px;
    min-width: 100%;
  }

  .ax-right-list-box > .left-box > .box {
    align-items: center;
    display: flex;
    margin-right: 0;
  }

  .ax-right-list-box > .left-box > .box > .img {
    height: 60px;
    margin-bottom: 0;
    margin-right: 15px;
    width: 60px;
  }

  .ax-right-list-box > .left-box > .box > .title {
    font-size: 20px;
    line-height: 23px;
    max-width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .ax-right-list-box > .right-box > .list-box > .item.bordered > .box {
    border-bottom: none;
  }
}
