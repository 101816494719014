.sh-left-content-button-card {
  margin: 47px 14.3056% 47px 14.4444%;
}

.sh-left-right-content-block {
  margin-top: 123px;
}

.sh-left-right-content-block > .ax-left-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

@media screen and (max-width: 639px) {
  .sh-left-content-button-card {
    margin: 50px 7.2464%;
  }

  .sh-left-content-button-card > .ax-left-content-button-card {
    flex-wrap: wrap;
    padding: 32px 27px;
  }

  .sh-left-content-button-card > .ax-left-content-button-card > .btn-action {
    width: 100%;
  }

  .sh-left-content-button-card > .ax-left-content-button-card > .description {
    font-size: 16px;
    line-height: 24px;
    margin: 0 10.8696% 11px 10.8696%;
    text-align: center;
  }

  .sh-left-right-content-block {
    margin-top: 40px;
  }

  .sh-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.7295%;
  }

  .sh-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block {
    flex-wrap: wrap;
  }

  .sh-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block {
    flex-wrap: wrap-reverse;
  }

  .sh-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block:not(:last-child),
  .sh-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
    margin-bottom: 47px;
  }

  .sh-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .image-box,
  .sh-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .image-box {
    margin-bottom: 0;
  }

  .sh-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box,
  .sh-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box {
    text-align: center;
    margin-bottom: 31px;
  }

  .sh-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .title,
  .sh-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .title {
    color: #234171;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 33px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .sh-left-content-button-card {
    margin: 47px 7.1528% 47px 7.2222%;
  }

  .sh-left-content-button-card > .ax-left-content-button-card {
    padding: 17.5px 33px;
  }

  .sh-left-content-button-card > .ax-left-content-button-card > .description {
    margin-right: 33px;
  }

  .sh-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }
}
