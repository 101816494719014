.ax-centre-banner {
  color: #FFFFFF;
  position: relative;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  min-height: 320px;
  text-align: center;
  z-index: 1;
}

.ax-centre-banner > .backdrop {
  background: linear-gradient(360deg, #313C47 4.36%, rgba(49, 60, 71, 0) 217.11%);
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

.ax-centre-banner > .content-box .btn-action {
  margin: 8px
}

.ax-centre-banner > .content-box .description {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
}

.ax-centre-banner > .content-box .title {
  font-weight: bold;
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 13px;
}

.ax-centre-banner > .content-box .button-box {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
