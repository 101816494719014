@import "../../../assets/style/variables/colors.scss";

#submission-success {
    font-family: "Roboto";

    .huge {
        font-size: 3rem;
    }

    button {
        background-color: $color-primary-light !important;
    }
}
