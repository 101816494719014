.ax-header-hero {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  padding: 110px 14.4444% 134px 14.4444%;
}

.ax-header-hero > .breadcrumbs > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-li > .link,
.ax-header-hero > .breadcrumbs > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-separator {
  color: #49F7D9;
  font-size: 16px;
  line-height: 19px;
}

.ax-header-hero > .breadcrumbs > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-li > .link.current {
  color: #FFFFFF;
}

.ax-header-hero > .text-box {
  margin-bottom: 9px;
}

.ax-header-hero > .text-box > .subtitle {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 23px;
}

.ax-header-hero > .text-box > .title {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 2px;
}

@media screen and (max-width: 1064px) {
  .ax-header-hero {
    padding: 55px 7.2222% 67px 7.2222%;
  }
}

@media screen and (max-width: 639px) {
  .ax-header-hero {
    padding: 47px 7.7295% 48px 7.7295%;
  }

  .ax-header-hero > .breadcrumbs > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-li > .link,
  .ax-header-hero > .breadcrumbs > .MuiBreadcrumbs-ol > .MuiBreadcrumbs-separator {
    font-size: 14px;
    line-height: 16px;
  }

  .ax-header-hero > .text-box {
    margin-bottom: 20px;
  }

  .ax-header-hero > .text-box > .subtitle {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
  }

  .ax-header-hero > .text-box > .title {
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 5px;
  }
}
