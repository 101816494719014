.snmc-card-blocks {
  margin: 49px 14.4444% 0 14.4444%;
}

.snmc-card-blocks > .ax-card-blocks {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.snmc-card-blocks > .ax-card-blocks > .item {
  align-items: stretch;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  padding: 50px 95px 30px 95px;
}

.snmc-card-blocks > .ax-card-blocks > .item:not(:last-child) {
  margin-bottom: 61px;
}

.snmc-card-blocks > .ax-card-blocks > .item > .image-box {
  align-items: center;
  display: flex;
  justify-content: center;
}

.snmc-card-blocks > .ax-card-blocks > .item > .image-box > .img {
  height: auto;
  max-width: 280px;
  width: 100%;
}

.snmc-card-blocks > .ax-card-blocks > .item > .text-box {
  flex: 1;
}

.snmc-card-blocks > .ax-card-blocks > .item > .text-box.even {
  margin-left: 120px;
}

.snmc-card-blocks > .ax-card-blocks > .item > .text-box.odd {
  margin-right: 100px;
}

.snmc-card-blocks > .ax-card-blocks > .item > .text-box > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.snmc-card-blocks > .ax-card-blocks > .item > .text-box > .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 9px;
}

.snmc-left-right-content-block {
  margin-top: 129px;
}

.snmc-left-right-content-block > .ax-left-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.snmc-text1 {
  margin-top: 150px;
  text-align: center;
}

.snmc-text1 > .text {
  color: #234171;
  font-family: Roboto;
  font-style: normal;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  margin: 0 20.0694%;
}

.snmc-text2 {
  margin: 47px 14.4444% 0 14.4444%;
}

.snmc-text2 > .text {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: #667DA3;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 51px 86px 69px 86px;
  text-align: justify;
}

.snmc-title {
  margin-top: 161px;
}

.snmc-title > .ax-title > .main {
  margin: 0 34.4444% 0 34.375%;
}

.snmc-two-column-full-width {
  margin-top: 50px;
}

@media screen and (max-width: 639px) {
  .snmc-left-right-content-block {
    margin-top: 40px;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.7295%;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block {
    flex-wrap: wrap;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block {
    flex-wrap: wrap-reverse;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block:not(:last-child),
  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
    margin-bottom: 36px;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .image-box,
  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .image-box {
    margin-bottom: 0;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box,
  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box {
    text-align: center;
    margin-bottom: 33px;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .btn-action,
  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .btn-action {
    margin: 22px 0;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .list-box,
  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .list-box {
    margin-top: 0;
    text-align: left;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .title1,
  .snmc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .title1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 34px;
  }

  .snmc-text1 {
    margin-top: 56px;
  }

  .snmc-text1 > .text {
    font-size: 20px;
    line-height: 24px;
    margin: 0 7.9710%;
  }

  .snmc-text2 {
    margin: 35px 7.7295% 0 7.7295%;
  }

  .snmc-text2 > .text {
    padding: 31px 23px 47px 23px;
  }

  .snmc-title {
    margin-top: 32px;
  }

  .snmc-title > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 16.6667%;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .snmc-card-blocks {
    margin: 49px 7.2222% 0 7.2222%;
  }

  .snmc-card-blocks > .ax-card-blocks > .item {
    padding: 25px 47.5px 15px 47.5px;
  }

  .snmc-card-blocks > .ax-card-blocks > .item:not(:last-child) {
    margin-bottom: 30.5px;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .text-box.even {
    margin-left: 60px;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .text-box.odd {
    margin-right: 50px;
  }

  .snmc-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }

  .snmc-text1 > .text {
    margin: 0 10.0347%;
  }

  .snmc-text2 {
    margin: 47px 7.2222% 0 7.2222%;
  }

  .snmc-text2 > .text {
    padding: 25.5px 43px 34.5px 43px;
  }

  .snmc-title > .ax-title > .main {
    margin: 0 17.2222% 0 17.1875%;
  }
}

@media screen and (max-width: 767px) {
  .snmc-card-blocks {
    margin: 23px 9.1787% 0 9.1787%;
  }

  .snmc-card-blocks > .ax-card-blocks > .item {
    align-items: flex-start;
    padding: 44px 31px 38px 31px;
  }

  .snmc-card-blocks > .ax-card-blocks > .item:not(:last-child) {
    margin-bottom: 26px;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .image-box {
    margin-bottom: 57px;
    min-width: 100%;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .image-box > .img {
    width: 100%;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .text-box {
    flex: 1;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .text-box.even {
    margin-left: 0;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .text-box.odd {
    margin-right: 0;
  }

  .snmc-card-blocks > .ax-card-blocks > .item > .text-box > .title {
    margin: 0 20px 19px 20px;
  }
}
