.ax-column-card-grid {
  display: grid;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  gap: 20px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: minmax(auto, 325px) minmax(auto, 325px) minmax(auto, 325px);
}

.ax-column-card-grid > .item {
  background-color: #FFFFFF;
  border: 1px solid #4378DF;
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.ax-column-card-grid > .item > .card {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px 25px 35px 25px;
}

.ax-column-card-grid > .item > .card > .button-box {
  max-width: 250px;
  width: 100%;
}

.ax-column-card-grid > .item > .card > .button-box > .btn-action {
  text-transform: capitalize;
}

.ax-column-card-grid > .item > .card > .top-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ax-column-card-grid > .item > .card > .top-box > .image-box {
  margin-bottom: 30px;
}

.ax-column-card-grid > .item > .card > .top-box > .image-box > .img {
  height: 150px;
  max-width: 100%;
}

.ax-column-card-grid > .item > .card > .top-box > .text-box {
  max-width: 250px;
  width: 100%;
}

.ax-column-card-grid > .item > .card > .top-box > .text-box > .description {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

.ax-column-card-grid > .item > .card > .top-box > .text-box > .title {
  color: #4378DF;
  font-size: 25px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: 15px;
}

.ax-column-card-grid > .item > .tick-box {
  position: relative;
}

.ax-column-card-grid > .item > .tick-box > .img {
  background-color: #FFFFFF;
  border-radius: 50%;
  height: 35px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
}

@media screen and (max-width: 639px) {
  .ax-column-card-grid {
    grid-template-columns: 1fr;
  }

  .ax-column-card-grid > .item > .card {
    margin: 43px 24px 32px 24px;
  }

  .ax-column-card-grid > .item > .card > .button-box > .btn-action {
    width: 100%;
  }

  .ax-column-card-grid > .item > .card > .top-box > .text-box > .description {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 640px) and (max-width: 780px) {
  .ax-column-card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 781px) and (max-width: 1023px) {
  .ax-column-card-grid {
    grid-template-columns: minmax(325px, 1fr) minmax(325px, 1fr);
  }
}
