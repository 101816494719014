.snwc-image-right-title-description-card {
  margin: 40px 14.4444% 0 14.4444%;
}

.snwc-image-right-title-description-card > .ax-column-card-grid2 > .item {
  border-bottom: solid 8px#47BEDC;
  padding: 46px 63px 47px 63px;
}

.snwc-image-right-title-description-card > .ax-column-card-grid2 > .item > .text-box > .description > .span {
  font-weight: bold;
}

.snwc-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box > .img {
  height: 150px;
  width: auto;
}

.snwc-left-right-content-block {
  margin-top: 140px;
}

.snwc-left-right-content-block > .ax-left-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.snwc-text {
  color: #506A96;
  margin-top: 42px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}

.snwc-title1 {
  margin-top: 94px;
}

.snwc-title1 > .ax-title > .main {
  color: #254476;
  margin-bottom: 20px;
}

.snwc-title1 > .ax-title > .sub {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  margin: 0 25.2083% 0 25.2778%;
}

.snwc-title2 {
  margin-top: 124px;
}

.snwc-title2 > .ax-title > .main {
  color: #254476;
  font-size: 36px;
  line-height: 42px;
  margin: 0 21.1111% 18px 21.1111%;
}

.snwc-title2 > .ax-title > .sub {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.snwc-two-column-full-width {
  margin-top: 80px;
}

@media screen and (max-width: 639px) {
  .snwc-image-right-title-description-card {
    margin: 29px 7.7295% 0 7.7295%;
  }

  .snwc-image-right-title-description-card > .ax-column-card-grid2 {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    row-gap: 27px;
  }

  .snwc-image-right-title-description-card > .ax-column-card-grid2 > .item {
    padding: 23px 31.5px 23.5px 31.5px;
  }

  .snwc-image-right-title-description-card > .ax-column-card-grid2 > .item > .text-box > .description > .span {
    font-weight: bold;
  }

  .snwc-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box > .img {
    height: 150px;
    width: auto;
  }

  .snwc-left-right-content-block {
    margin-top: 40px;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.7295%;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block {
    flex-wrap: wrap;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block {
    flex-wrap: wrap-reverse;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block:not(:last-child),
  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
    margin-bottom: 37px;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .image-box,
  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .image-box {
    margin-bottom: 0;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box,
  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box {
    text-align: center;
    margin-bottom: 34px;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .title1,
  .snwc-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .title1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 34px;
  }

  .snwc-text {
    color: #506A96;
    margin-top: 42px;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
  }

  .snwc-title1 {
    margin-top: 36px;
  }

  .snwc-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 16.4251% 29px 16.4251%;
  }

  .snwc-title1 > .ax-title > .sub {
    margin: 0 7.7295%;
  }

  .snwc-title2 {
    margin-top: 48px;
  }

  .snwc-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 28px 7.7295%;
  }

  .snwc-title2 > .ax-title > .sub {
    margin: 0 14.0097%;
  }

  .snwc-two-column-full-width {
    margin-top: 44px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .snwc-image-right-title-description-card {
    margin: 40px 7.2222% 0 7.2222%;
  }

  .snwc-image-right-title-description-card > .ax-column-card-grid2 > .item {
    padding: 23px 31.5px 23.5px 31.5px;
  }

  .snwc-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }

  .snwc-title1 > .ax-title > .sub {
    margin: 0 12.6042% 0 12.6389%;
  }

  .snwc-title2 > .ax-title > .main {
    margin: 0 10.5556% 18px 10.5556%;
  }
}
