.ax-custom-tabs > .MuiTabs-root .tab {
  color: #234171;
  display: flex;
  flex-direction: row;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.ax-custom-tabs > .MuiTabs-root .tab > .img {
  height: auto;
  max-width: 50px;
}

.ax-custom-tabs > .scroll-to-top {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.ax-custom-tabs > .scroll-to-top > .link:hover > .img {
  background-color: #c6d4dc;
}

.ax-custom-tabs > .scroll-to-top > .link > .img {
  background-color: #667c9f;
  border-radius: 5px;
  height: 50px;
  width: 50px;
}

@media screen and (max-width: 767px) {
  .ax-custom-tabs {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .ax-custom-tabs > .scroll-to-top {
    right: calc(50% - 670px);
  }
}
