.snhl-image-right-title-description-card {
  margin: 47px 14.375% 0 14.4444%;
}

.snhl-image-right-title-description-card > .ax-column-card-grid2 {
  gap: 34px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.snhl-image-right-title-description-card > .ax-column-card-grid2 > .item {
  border-bottom: solid 7px#47DBDB;
  padding: 20px 17px;
}

.snhl-image-right-title-description-card > .ax-column-card-grid2 > .item > .text-box {
  text-align: center;
}

.snhl-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box {
  margin: 0;
}

.snhl-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box > img {
  border-radius: 5px;
  width: 100%;
}

.snhl-left-content-image-card-block {
  margin: 130px 6.8056% 0 6.875%;
}

.snhl-left-image-column-cards-block {
  margin: 59px 14.4444% 0 14.4444%;
}

.snhl-left-right-content-block {
  margin-top: 135px;
}

.snhl-left-right-content-block > .ax-left-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.snhl-title {
  margin-top: 140px;
}

.snhl-title > .ax-title > .main {
  color: #234171;
  font-size: 40px;
  line-height: 46px;
}

.snhl-two-column-full-width {
  margin-top: 140px;
}

@media screen and (max-width: 639px) {
  .snhl-image-right-title-description-card {
    margin: 47px 14.375% 0 14.4444%;
  }

  .snhl-image-right-title-description-card > .ax-column-card-grid2 {
    gap: 34px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .snhl-image-right-title-description-card > .ax-column-card-grid2 > .item {
    border-bottom: solid 7px#47DBDB;
    padding: 20px 17px;
  }

  .snhl-image-right-title-description-card > .ax-column-card-grid2 > .item > .text-box {
    text-align: center;
  }

  .snhl-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box {
    margin: 0;
  }

  .snhl-image-right-title-description-card > .ax-column-card-grid2 > .item > .top-box > .image-box > img {
    border-radius: 5px;
    width: 100%;
  }

  .snhl-left-content-image-card-block {
    margin: 53px 7.7295% 0 7.7295%;
  }

  .snhl-left-image-column-cards-block {
    margin: 16px 10.8696% 0 10.8696%;
  }

  .snhl-left-image-column-cards-block > .ax-left-image-column-cards-block > .list-box > .item {
    display: flex;
    padding: 23px 25px;
  }

  .snhl-left-image-column-cards-block > .ax-left-image-column-cards-block > .list-box > .item > .img {
    height: 34px;
    margin: 0 19px 0 0;
    width: 34px;
  }

  .snhl-left-right-content-block {
    margin-top: 40px;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.7295%;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block {
    flex-wrap: wrap;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block {
    flex-wrap: wrap-reverse;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block:not(:last-child),
  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
    margin-bottom: 44px;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .image-box,
  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .image-box {
    margin-bottom: 0;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box,
  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box {
    text-align: center;
    margin-bottom: 41px;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-left-content-block > .text-box > .title1,
  .snhl-left-right-content-block > .ax-left-right-content-block > .ax-right-content-block > .text-box > .title1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 31px;
  }

  .snhl-title {
    margin-top: 31px;
  }

  .snhl-title > .ax-title > .main {
    color: #254476;
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 29px 7.7295%;
  }

  .snhl-title > .ax-title > .sub {
    line-height: 24px;
    margin: 0 7.7295%;
  }

  .snhl-two-column-full-width {
    margin-top: 59px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .snhl-image-right-title-description-card {
    margin: 47px 7.1875% 0 7.2222%;
  }

  .snhl-left-content-image-card-block {
    margin: 130px 3.4028% 0 3.4375%;
  }

  .snhl-left-image-column-cards-block {
    margin: 59px 7.2222% 0 7.2222%;
  }

  .snhl-left-right-content-block > .ax-left-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }
}
