.ax-image-block {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ax-image-block > .image-box > .img {
  height: auto;
  width: 100%;
}
