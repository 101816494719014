.ax-image-card-grid {
  display: grid;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  gap: 33px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.ax-image-card-grid > .item {
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.ax-image-card-grid > .item > .image-box {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ax-image-card-grid > .item > .image-box > .img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 359px) {
  .ax-image-card-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 360px) and (max-width: 780px) {
  .ax-image-card-grid {
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 781px) and (max-width: 1023px) {
  .ax-image-card-grid {
    grid-template-columns: minmax(325px, 1fr) minmax(325px, 1fr);
  }
}
