.ax-right-content-block {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
}

.ax-right-content-block > .image-box {
  flex: 1;
}

.ax-right-content-block > .image-box > .img {
  border-radius: 5px;
  height: auto;
  max-width: 492px;
}

.ax-right-content-block > .text-box {
  flex: 1;
  margin-left: 67px;
}

.ax-right-content-block > .text-box > .btn-action {
  cursor: default;
  margin: 15px 0 30px 0;
  text-transform: none;
}

.ax-right-content-block > .text-box > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.ax-right-content-block > .text-box > .description > a {
  color: #2F6BCC;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
}

.ax-right-content-block > .text-box > .list-box {
  margin-top: 11px;
}

.ax-right-content-block > .text-box > .list-box > .item {
  display: flex;
}

.ax-right-content-block > .text-box > .list-box > .item:not(:last-child) {
  margin-bottom: 12px;
}

.ax-right-content-block > .text-box > .list-box > .item > .image-box {
  margin-right: 15px;
}

.ax-right-content-block > .text-box > .list-box > .item > .text-box {
  color: #516997;
  font-size: 16px;
  line-height: 24px;
}

.ax-right-content-block > .text-box > .note {
  color: #B7B7B7;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}

.ax-right-content-block > .text-box > .subtitle {
  color: #4378DF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 23px;
  text-transform: capitalize;
}

.ax-right-content-block > .text-box > .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 23px;
  text-transform: capitalize;
}

.ax-right-content-block > .text-box > .title1 {
  color: #234171;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 23px;
  text-transform: capitalize;
}

.ax-right-content-block > .text-box > .title1 > .span {
  color: #4378DF;
}

@media screen and (max-width: 959px) {
  .ax-right-content-block > .image-box {
    margin-bottom: 24px;
    min-width: 100%;
  }

  .ax-right-content-block > .image-box > .img {
    width: 100%;
  }

  .ax-right-content-block > .text-box {
    margin-bottom: 24px;
    margin-left: 0;
  }

  .ax-right-content-block > .text-box > .title {
    margin-bottom: 19px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1029px) {
  .ax-right-content-block > .image-box > .img {
    max-width: 344px;
  }
}

@media screen and (min-width: 1030px) and (max-width: 1129px) {
  .ax-right-content-block > .image-box > .img {
    max-width: 394px;
  }
}

@media screen and (min-width: 1130px) and (max-width: 1279px) {
  .ax-right-content-block > .image-box > .img {
    max-width: 443px;
  }
}
