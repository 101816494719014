.ax-left-right-content-block > .ax-left-content-block:not(:last-child),
.ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 959px) {
  .ax-left-right-content-block > .ax-left-content-block:not(:last-child),
  .ax-left-right-content-block > .ax-right-content-block:not(:last-child) {
    margin-bottom: 36px;
  }
}
