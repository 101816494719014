.paa-borderless-column-card-grid {
  margin: 67px 14.375% 0 14.4444%;
}

.paa-banner-left-text {
  margin: 80px 14.375% 0 14.4444%;
}

.paa-chart-box {
  margin: 80px 14.4444% 0 14.4444%;
}

.paa-chart-box > .image-box > .img {
  height: auto;
  margin-bottom: 40px;
  width: 100%;
}

.paa-chart-box > .title {
  color: #4378E0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.paa-column-card-grid {
  margin: 64px 14.375% 0 14.375%;
}

.paa-left-icon-card {
  margin: 76px 14.4444% 0 14.4444%;
}

.paa-left-right-content-block {
  margin: 100px 14.5139% 0 14.5139%;
}

.paa-left-right-nested-list-block {
  margin-top: 187px;
}

.paa-right-list-block {
  margin: 156px 14.375% 0 14.4444%;
}

.paa-right-list-block > .item:not(:last-child) {
  margin-bottom: 88px;
}

.paa-steps {
  margin-top: 95px;
}

.paa-text1 {
  margin-top: 60px;
}

.paa-text2 {
  margin-top: 108px;
}

.paa-title1 {
  margin: 126px 36.1111% 0 36.3194%;
}

.paa-title2 {
  margin: 164px 35.4167% 0 35.625%;
}

.paa-title3 {
  margin-top: 98px;
}

.paa-title3 > .ax-title > .main {
  margin-bottom: 0;
}

.paa-title-with-button1 {
  margin-top: 38px;
}

.paa-title-with-button2 {
  margin-top: 125px;
}

.paa-two-column-cards {
  margin: 61px 10.2778% 0 10.3472%;
}

.paa-two-column-full-width {
  margin-top: 50px;
}

.paa-video-box {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 135px 14.5833% 0 14.4444%;
}

.paa-video-box > .flex-box {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.paa-video-box > .flex-box > .left-box {
  flex: 1;
}

.paa-video-box > .flex-box > .left-box > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.paa-video-box > .flex-box > .left-box > .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.paa-video-box > .flex-box > .right-box {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media screen and (max-width: 639px) {
  .paa-borderless-column-card-grid {
    margin: 40px 7.2464% 0 7.2464%;
  }

  .paa-banner-left-text {
    margin: 49px 6.7633% 0 7.7295%;
  }

  .paa-chart-box {
    margin: 97px 0 0 0;
  }

  .paa-chart-box > .image-box {
    margin: 0 7.2464% 0 7.2464%;
  }

  .paa-chart-box > .title {
    margin: 0 7.2464% 0 7.2464%;
  }

  .paa-column-card-grid {
    margin: 40px 7.2464% 0 7.2464%;
  }

  .paa-column-card-grid > .ax-column-card-grid {
    row-gap: 36px;
  }

  .paa-left-icon-card {
    margin: 29px 7.4879% 0 7.7295%;
  }

  .paa-left-right-content-block {
    margin: 38px 7.7295% 0 7.7295%;
  }

  .paa-right-list-block {
    margin: 72px 7.2464% 0 7.2464%;
  }

  .paa-right-list-block > .item:not(:last-child) {
    margin-bottom: 72px;
  }

  .paa-text1 {
    margin: 38px 7.2464% 0 7.2464%;
  }

  .paa-text1 > .ax-text > .main {
    font-size: 29px;
    line-height: 34px;
  }

  .paa-text2 {
    margin: 39px 7.2464% 0 7.2464%;
  }

  .paa-text2 > .ax-text2 > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paa-title1 {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .paa-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paa-title2 {
    margin: 44px 7.7295% 0 7.7295%;
  }

  .paa-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paa-title3 {
    margin: 42px 7.2464% 0 7.2464%;
  }

  .paa-title3 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paa-title-with-button1 {
    margin-top: 26px;
  }

  .paa-title-with-button1 > .ax-title-with-button > .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.2464% 16px 7.2464%;
  }

  .paa-title-with-button1 > .ax-title-with-button > .description {
    margin: 0 7.2464% 0 7.2464%;
  }

  .paa-title-with-button2 {
    margin-top: 74px;
  }

  .paa-title-with-button2 > .ax-title-with-button2 > .title {
    font-size: 30px;
    line-height: 32px;
    margin: 0 7.2464% 23px 7.2464%;
  }

  .paa-title-with-button2 > .ax-title-with-button2 > .description {
    margin: 0 7.2464% 0 7.2464%;
  }

  .paa-two-column-cards {
    margin: 48px 7.0048% 0 6.7633%;
  }

  .paa-two-column-full-width {
    margin-top: 41px;
  }

  .paa-video-box {
    margin: 68px 7.2464% 0 7.2464%;
  }

  .paa-video-box > .flex-box > .left-box {
    margin-bottom: 28px;
    min-width: 100%;
  }

  .paa-video-box > .flex-box > .right-box {
    min-width: 100%;
  }

  .paa-video-box > .flex-box > .right-box > .react-player {
    height: 211px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .paa-borderless-column-card-grid {
    margin: 67px 7.1875% 0 7.2222%;
  }

  .paa-banner-left-text {
    margin: 80px 7.1875% 0 7.2222%;
  }

  .paa-chart-box {
    margin: 80px 7.2222% 0 7.2222%;
  }

  .paa-column-card-grid {
    margin: 64px 7.1875% 0 7.1875%;
  }

  .paa-left-icon-card {
    margin: 76px 7.2222% 0 7.2222%;
  }

  .paa-left-right-content-block {
    margin: 100px 7.2569% 0 7.2569%;
  }

  .paa-right-list-block {
    margin: 156px 7.1875% 0 7.2222%;
  }

  .paa-title1 {
    margin: 126px 18.0556% 0 18.1597%;
  }

  .paa-title2 {
    margin: 164px 17.7083% 0 17.8125%;
  }

  .paa-two-column-cards {
    margin: 61px 5.139% 0 5.1736%;
  }

  .paa-video-box {
    margin: 135px 7.2917% 0 7.2222%;
  }
}

@media screen and (min-width: 640px) and (max-width: 799px) {
  .paa-video-box > .flex-box > .left-box {
    margin-bottom: 28px;
    min-width: 100%;
  }

  .paa-video-box > .flex-box > .right-box {
    min-width: 100%;
  }

  .paa-video-box > .flex-box > .right-box > .react-player {
    height: 360px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
