.ax-title-with-button {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  min-height: 335px;
}

.ax-title-with-button > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  margin: 0 28.0556%;
  text-align: center;
}

.ax-title-with-button > .button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
}

.ax-title-with-button > .button-box > .line {
  border: 2px solid #D0CDCD;
  height: 0;
  margin: 0 18px;
  width: 20px;
}

.ax-title-with-button > .title {
  color: #234171;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 48px;
  padding-top: 133px;
  text-align: center;
}
