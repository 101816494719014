.ps-box {
  background-color: #FFFFFF;
  border: 0.5px solid #B8B8B8;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 40px 14.375%;
  padding: 58px 0;
}

.ps-box > .button-box {
  margin-top: 49px;
  text-align: center;
}

.ps-box > .button-box > .MuiButton-root:not(:last-child) {
  margin-right: 24px;
}

.ps-box > .form-box {
  padding: 0 58px;
}

.ps-box > .form-box > .ps-form-control {
  margin-bottom: 36px;
}

.ps-box > .form-box > .ps-form-grid {
  align-items: flex-start;
  display: grid;
  gap: 40px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 16px 36px -16px;
}

.ps-box > .form-box > .ps-form-grid.col2 {
  grid-template-columns: 1fr 1fr;
}

.ps-box > .form-box .checkbox-form-control > legend {
  color: #234171;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 13px;
}

.ps-box > .form-box .checkbox-form-control > .checkbox-form-group .MuiFormControlLabel-label {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
}

.ps-box > .form-box .checkbox-form-control > .checkbox-form-group .MuiFormHelperText-root {
  margin: 3px 0 6px 0;
}

.ps-box > .form-box .checkbox-form-control > .checkbox-form-group.tnc {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

.ps-box > .form-box .radio-form-control > legend {
  color: #234171;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 13px;
}

.ps-box > .form-box .radio-form-control > .MuiFormHelperText-root {
  margin: 3px 0 6px 0;
}

.ps-box > .form-box .radio-form-control > .radio-button-group > .radio {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
}

.ps-box > .form-box .radio-form-control > .radio-button-group > .radio .text .extra {
  background-color: #4980F0;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  padding: 5px;
}

.ps-box > .text-required {
  color: #B7B7B7;
  font-size: 16px;
  line-height: 19px;
  padding: 0 58px 40px 58px;
  text-align: left;
}

.ps-box > .total-box {
  background-color: #4980F0;
  color: #FFFFFF;
  margin-bottom: 30px;
  padding: 26px 58px;
}

.ps-box > .total-box > .label {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
}

.ps-box > .total-box > .amount {
  font-size: 40px;
  line-height: 40px;
}

.ps-success-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 108px 0 128px 0;
  text-align: center;
}

.ps-success-box > .image-box {
  margin-bottom: 40px;
}

.ps-success-box > .image-box > .img-success {
  height: auto;
  width: 371px;
}

.ps-success-box > .note {
  color: #B7B7B7;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.ps-success-box > .title-box > .title {
  color: #254476;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  margin-bottom: 8px;
}

.ps-success-box > .title-box > .title > .highlight {
  color: #4378DF;
}

.ps-success-box > .title-box > .subtitle {
  color: #506A96;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
}

.ps-text {
  margin: 20px 14.375% 125px 14.375%;
}

.ps-text > .additional-note {
  color: #808080;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
}

.ps-title {
  margin: 110px 27.6389% 0 27.6389%;
}

@media screen and (max-width: 959px) {
  .ps-box > .form-box > .ps-form-grid.col2 {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 639px) {
  .ps-box {
    margin: 40px 7.7295% 20px 7.7295%;
    padding: 41px 0;
  }

  .ps-box > .button-box {
    margin-top: 20px;
    text-align: center;
  }

  .ps-box > .button-box > .MuiButton-root {
    width: 100%;
  }

  .ps-box > .button-box > .MuiButton-root:not(:last-child) {
    margin-bottom: 20px;
  }

  .ps-box > .form-box {
    padding: 0 21px;
  }

  .ps-box > .form-box > .ps-form-grid {
    row-gap: 13px;
    margin-bottom: 13px;
  }

  .ps-box .text-required {
    padding: 0 21px 40px 21px;
  }

  .ps-text {
    margin: 20px 7.7295% 52px 7.7295%;
  }

  .ps-title {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .ps-title > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 38px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .ps-box {
    margin: 40px 7.1875%;
  }

  .ps-text {
    margin: 20px 7.1875% 125px 7.1875%;
  }

  .ps-title {
    margin: 110px 13.8194% 0 13.8194%;
  }
}
