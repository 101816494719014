@import '../../../assets/style/variables/colors.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sub-menu-bar {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  background-color: #f0f9fe;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  position: absolute;
  z-index: 9;
  animation: fadeIn 0.5s ease-in-out;

  .sub-menu {
    .menu {
      .item {
        min-width: 250px;
        .link-box {
          border-bottom: 1px solid #fff;
          color: #102f5b;

          &:hover, &.active {
            background-color: $color-primary-light;
            transition: 0.5s ease-in-out;
            color: #fff;
          }
          a,
          span {
            color: inherit;
            padding: 16px;
            font-size: 16px;
            font-weight: 500;
            text-decoration: #102f5b;
            display: block;
          }

          span {
            cursor: default;
          }
        }
      }
    }
  }
}
