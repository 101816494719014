.paw-borderless-column-card-grid {
  margin: 66px 14.4444% 0 14.4444%;
}

.paw-banner-left-text {
  margin: 80px 14.375% 0 14.4444%;
}

.paw-chart-box {
  margin: 80px 14.4444% 0 14.4444%;
}

.paw-chart-box > .image-box > .img {
  height: auto;
  margin-bottom: 40px;
  width: 100%;
}

.paw-chart-box > .title {
  color: #4378E0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.paw-column-card-grid {
  margin: 64px 14.375% 0 14.375%
}

.paw-left-icon-card {
  margin: 76px 14.4444% 0 14.4444%;
}

.paw-left-right-content-block {
  margin: 100px 14.5139% 0 14.5139%;
}

.paw-left-right-content-block > .paw-lrcb > .ax-left-content-block > .image-box > .img,
.paw-left-right-content-block > .paw-lrcb > .ax-right-content-block > .image-box > .img {
  border-radius: 5px;
  max-width: 450px;
}

.paw-left-right-content-block > .paw-lrcb > .ax-left-content-block > .text-box > .title,
.paw-left-right-content-block > .paw-lrcb > .ax-right-content-block > .text-box > .title {
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 23px;
}

.paw-left-right-nested-list-block {
  margin-top: 187px;
}

.paw-right-list-block {
  margin: 172px 14.2361% 0 14.2361%;
}

.paw-right-list-block > .item:not(:last-child) {
  margin-bottom: 88px;
}

.paw-steps {
  margin-top: 95px;
}

.paw-text1 {
  margin-top: 60px;
}

.paw-text2 {
  margin-top: 108px;
}

.paw-title1 {
  margin: 126px 36.1111% 0 36.3194%;
}

.paw-title2 {
  margin: 164px 35.4167% 0 35.625%;
}

.paw-title3 {
  margin-top: 98px;
}

.paw-title-with-button1 {
  margin-top: 38px;
}

.paw-title-with-button1 > .ax-title-with-button > .title {
  margin-left: 24%;
  margin-right: 24%;
}

.paw-title-with-button2 {
  margin-top: 125px;
}

.paw-two-column-cards {
  margin: 61px 10.2778% 0 10.3472%;
}

.paw-two-column-full-width {
  margin-top: 50px;
}

.paw-video-box {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 159px 14.5833% 0 14.4444%;
}

.paw-video-box > .flex-box {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.paw-video-box > .flex-box > .left-box {
  flex: 1;
}

.paw-video-box > .flex-box > .left-box > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.paw-video-box > .flex-box > .left-box > .list-box {
  color: #506A96;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
}

.paw-video-box > .flex-box > .left-box > .list-box > .item {
  display: flex;
}

.paw-video-box > .flex-box > .left-box > .list-box > .item:not(:last-child) {
  margin-bottom: 13px;
}

.paw-video-box > .flex-box > .left-box > .list-box > .item > .image-box {
  margin-bottom: 14px;
  margin-right: 18px;
}

.paw-video-box > .flex-box > .left-box > .title {
  color: #4378DF;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.paw-video-box > .flex-box > .right-box {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media screen and (max-width: 639px) {
  .paw-borderless-column-card-grid {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .paw-borderless-column-card-grid > .ax-borderless-column-card-grid {
    grid-template-columns: 1fr;
  }

  .paw-banner-left-text {
    margin: 49px 6.7633% 0 7.7295%;
  }

  .paw-chart-box {
    margin: 97px 0 0 0;
  }

  .paw-chart-box > .image-box {
    margin: 0 14.9758% 0 14.9758%;
  }

  .paw-chart-box > .title {
    margin: 0 7.2464% 0 7.0048%;
  }

  .paw-column-card-grid {
    margin: 40px 10.8696% 0 10.8696%;
  }

  .paw-column-card-grid > .ax-column-card-grid {
    row-gap: 36px;
  }

  .paw-left-icon-card {
    margin: 29px 7.4879% 0 7.7295%;
  }

  .paw-left-right-content-block {
    margin: 38px 7.7295% 0 7.7295%;
  }

  .paw-right-list-block {
    margin: 172px 7.2464% 0 7.2464%;
  }

  .paw-right-list-block > .item:not(:last-child) {
    margin-bottom: 72px;
  }

  .paw-text1 {
    margin: 38px 14.7343% 0 14.7343%;
  }

  .paw-text1 > .ax-text > .main {
    font-size: 29px;
    line-height: 34px;
  }

  .paw-text2 {
    margin: 39px 14.4928% 0 14.4928%;
  }

  .paw-text2 > .ax-text2 > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paw-title1 {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .paw-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paw-title2 {
    margin: 44px 7.7295% 0 7.7295%;
  }

  .paw-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paw-title3 {
    margin: 42px 12.0773% 0 12.3188%;
  }

  .paw-title3 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
  }

  .paw-title-with-button1 {
    margin-top: 26px;
  }

  .paw-title-with-button1 > .ax-title-with-button > .title {
    font-size: 30px;
    line-height: 35px;
    margin: 0 16.9082% 16px 17.1498%;
  }

  .paw-title-with-button1 > .ax-title-with-button > .description {
    margin: 0 7.0048% 0 6.5217%;
  }

  .paw-title-with-button2 {
    margin-top: 74px;
  }

  .paw-title-with-button2 > .ax-title-with-button2 > .title {
    font-size: 30px;
    line-height: 32px;
    margin: 0 7.971% 23px 7.971%;
  }

  .paw-title-with-button2 > .ax-title-with-button2 > .description {
    margin: 0 7.971% 0 4.5894%;
  }

  .paw-two-column-cards {
    margin: 48px 7.0048% 0 6.7633%;
  }

  .paw-two-column-full-width {
    margin-top: 41px;
  }

  .paw-video-box {
    margin: 68px 7.2464% 0 7.2464%;
  }

  .paw-video-box > .flex-box > .left-box {
    margin-bottom: 28px;
    min-width: 100%;
  }

  .paw-video-box > .flex-box > .right-box {
    min-width: 100%;
  }

  .paw-video-box > .flex-box > .right-box > .react-player {
    height: 211px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .paw-borderless-column-card-grid {
    margin: 66px 7.2222% 0 7.2222%;
  }

  .paw-banner-left-text {
    margin: 80px 7.1875% 0 7.2222%;
  }

  .paw-chart-box {
    margin: 80px 7.2222% 0 7.2222%;
  }

  .paw-column-card-grid {
    margin: 47px 7.2222% 0 7.2222%
  }

  .paw-left-icon-card {
    margin: 76px 7.2222% 0 7.2222%;
  }

  .paw-left-right-content-block {
    margin: 100px 7.2569% 0 7.2569%;
  }

  .paw-right-list-block {
    margin: 136px 7.1181% 0 7.1181%;
  }

  .paw-title1 {
    margin: 126px 16.7014% 0 16.7361%;
  }

  .paw-title2 {
    margin: 160px 16.25% 0 16.2153%;
  }

  .paw-two-column-cards {
    margin: 65px 5.139% 0 5.1736%;
  }

  .paw-video-box {
    margin: 159px 7.2917% 0 7.2222%;
  }
}

@media screen and (min-width: 640px) and (max-width: 799px) {
  .paw-video-box > .flex-box > .left-box {
    margin-bottom: 28px;
    min-width: 100%;
  }

  .paw-video-box > .flex-box > .right-box {
    min-width: 100%;
  }

  .paw-video-box > .flex-box > .right-box > .react-player {
    height: 360px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

