.sax-image-right-title-description-card {
  margin: 58px 14.5139% 0 14.5139%;
}

.sax-image-right-title-description-card > .ax-column-card-grid2 {
  gap: 34px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.sax-image-right-title-description-card > .ax-column-card-grid2 > .item {
  border-bottom: solid 7px#47DBDB;
  padding: 37px 25px;
}

.sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) {
  align-items: center;
  border: none;
  box-shadow: none;
  display: flex;
  padding: 0;
}

.sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) > .top-box,
.sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) > .top-box > .image-box {
  margin: 0;
}

.sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) > .top-box > .image-box > .img {
  width: 100%;
}

.sax-left-content-button-card {
  margin: 80px 14.375% 0 14.375%;
}

.sax-left-content-button-card > .ax-left-content-button-card {
  padding: 34px 56px;
}

.sax-left-content-button-card > .ax-left-content-button-card > .btn-action {
  margin-right: 76px;
}

.sax-left-content-button-card > .ax-left-content-button-card > .description {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  margin-right: 208px;
}

.sax-left-content-button-card > .ax-left-content-button-card > .description > a {
  color: #FFFFFF;
  font-weight: bold;
  text-decoration: none;
  text-decoration-color: #FFFFFF;
}

.sax-left-icon-card1 {
  margin: 72px 14.4444% 0 14.375%;
}

.sax-left-icon-card1 > .ax-left-icon-card > .text-box {
  color: #A6A6A6;
  font-size: 14px;
  line-height: 19px;
}

.sax-left-icon-card2 {
  margin: 24px 14.4444% 0 14.375%;
}

.sax-right-content-block1 {
  margin-top: 143px;
}

.sax-right-content-block1 > .ax-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.sax-right-content-block1 > .ax-right-content-block > .text-box > .title {
  color: #234171;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 21px;
}

.sax-right-content-block2 {
  margin-top: 143px;
}

.sax-right-content-block2 > .ax-right-content-block {
  margin: 0 14.4444% 0 14.375%;
}

.sax-terms {
  margin: 53px 14.4444% 0 14.375%;
}

.sax-terms > .terms {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  padding: 51px 48px 49px 48px;
}

.sax-terms > .terms > .title {
  color: #4378DF;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 8px;
}

.sax-terms > .terms > .list-box {
  color: #234171;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding-left: 20px;
}

.sax-terms > .terms > .list-box > li:not(:last-child) {
  margin-bottom: 24px;
}

.sax-title1 {
  margin-top: 190px;
}

.sax-title1 > .ax-title > .main {
  color: #234171;
  font-size: 40px;
  line-height: 46px;
}

.sax-title1 > .ax-title > .sub {
  color: #506A96;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.sax-title2 {
  margin-top: 117px;
}

.sax-title2 > .ax-title > .main {
  color: #234171;
  font-size: 40px;
  line-height: 46px;
  margin: 0 24.375% 34px 24.4444%;
}

.sax-title2 > .ax-title > .sub {
  color: #4378DF;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.sax-box {
  border: 0.5px solid #DBDBDB;
  border-radius: 5px;
  margin: 50px 14.3056% 90px 14.4444%;
  padding: 58px;
}

.sax-box > .button-box {
  margin-top: 49px;
  text-align: center;
}

.sax-box > .sax-form-grid {
  align-items: flex-start;
  display: grid;
  gap: 40px;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 0 16px 36px -16px;
}

.sax-box > .sax-form-grid.col2 {
  grid-template-columns: 1fr 1fr;
}

.sax-success-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 108px 0 128px 0;
  text-align: center;
}

.sax-success-box > .image-box {
  margin-bottom: 40px;
}

.sax-success-box > .image-box > .img-success {
  height: auto;
  width: 371px;
}

.sax-success-box > .note {
  color: #B7B7B7;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}

.sax-success-box > .title-box > .title {
  color: #254476;
  font-size: 40px;
  font-weight: bold;
  line-height: 47px;
  margin-bottom: 8px;
}

.sax-success-box > .title-box > .title > .highlight {
  color: #4378DF;
}

.sax-success-box > .title-box > .subtitle {
  color: #506A96;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 29px;
}

@media screen and (max-width: 959px) {
  .sax-box > .sax-form-grid.col2 {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 639px) {
  .sax-box {
    margin: 50px 7.7295%;
    padding: 29px 21px;
  }

  .sax-box > .button-box {
    margin-top: 20px;
    text-align: center;
  }

  .sax-box > .button-box > .MuiButton-root {
    width: 100%;
  }

  .sax-box > .sax-form-grid {
    row-gap: 13px;
    margin-bottom: 13px;
  }

  .sax-image-right-title-description-card {
    margin: 29px 10.8696% 0 10.8696%;
  }

  .sax-image-right-title-description-card > .ax-column-card-grid2 {
    row-gap: 25px;
  }

  .sax-image-right-title-description-card > .ax-column-card-grid2 > .item {
    padding: 37px 25px;
  }

  .sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) {
    align-items: center;
    border: none;
    box-shadow: none;
    display: flex;
    padding: 0;
  }

  .sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) > .top-box,
  .sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) > .top-box > .image-box {
    margin: 0;
  }

  .sax-image-right-title-description-card > .ax-column-card-grid2 > .item:nth-child(8) > .top-box > .image-box > .img {
    width: 100%;
  }

  .sax-left-content-button-card {
    margin: 38px 7.2464% 0 7.2464%;
  }

  .sax-left-content-button-card > .ax-left-content-button-card {
    flex-wrap: wrap;
    padding: 26px 26px 30px 26px;
  }

  .sax-left-content-button-card > .ax-left-content-button-card > .btn-action {
    background-color: #FFFFFF;
    background-image: none;
    color: #000000;
    margin-right: 0;
    width: 100%;
  }

  .sax-left-content-button-card > .ax-left-content-button-card > .description {
    margin: 0 0 15px 0;
  }

  .sax-left-icon-card1 {
    margin: 45px 7.2464% 0 7.2464%;
  }

  .sax-left-icon-card1 > .ax-left-icon-card {
    flex-wrap: wrap;
    padding: 27px 24px 20px 24px;
  }

  .sax-left-icon-card1 > .ax-left-icon-card > .img {
    margin: 0 0 15px 0;
  }

  .sax-left-icon-card1 > .ax-left-icon-card > .text-box {
    min-width: 100%;
  }

  .sax-left-icon-card2 {
    margin: 24px 7.7295% 0 7.7295%;
  }

  .sax-left-icon-card2 > .ax-left-icon-card {
    align-items: flex-start;
    padding: 28px 19px;
  }

  .sax-right-content-block1 {
    margin-top: 40px;
  }

  .sax-right-content-block1 > .ax-right-content-block {
    flex-wrap: wrap-reverse;
    margin: 0 7.2464%;
  }

  .sax-right-content-block1 > .ax-right-content-block > .image-box {
    margin: 0;
  }

  .sax-right-content-block1 > .ax-right-content-block > .text-box {
    margin: 0 0 23px 0;
    text-align: center;
  }

  .sax-right-content-block1 > .ax-right-content-block > .text-box > .description {
    margin-bottom: 40px;
  }

  .sax-right-content-block1 > .ax-right-content-block > .text-box > .title {
    color: #234171;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 39px;
  }

  .sax-right-content-block2 {
    margin-top: 50px;
  }

  .sax-right-content-block2 > .ax-right-content-block {
    flex-wrap: wrap-reverse;
    margin: 0 7.2464%;
  }

  .sax-right-content-block2 > .ax-right-content-block > .image-box {
    margin: 0;
  }

  .sax-right-content-block2 > .ax-right-content-block > .text-box {
    margin: 0 0 23px 0;
  }

  .sax-right-content-block2 > .ax-right-content-block > .text-box > .description {
    margin-bottom: 27px;
  }

  .sax-right-content-block2 > .ax-right-content-block > .text-box > .title {
    color: #234171;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 16px;
  }

  .sax-terms {
    margin: 35px 7.7295% 0 7.7295%;
  }

  .sax-terms > .terms {
    padding: 30px 27px 15px 27px;
  }

  .sax-title1 {
    margin: 40px 7.7295% 0 7.7295%;
  }

  .sax-title1 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 33px;
  }

  .sax-title2 {
    margin-top: 50px;
  }

  .sax-title2 > .ax-title > .main {
    font-size: 30px;
    line-height: 35px;
    margin: 0 7.7295% 40px 7.7295%;
  }

  .sax-title2 > .ax-title > .sub {
    margin: 0 7.7295%;
  }
}

@media screen and (max-width: 999px) {
  .sax-image-right-title-description-card > .ax-column-card-grid2 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .sax-image-right-title-description-card > .ax-column-card-grid2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 639px) {
  .sax-image-right-title-description-card > .ax-column-card-grid2 {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 640px) and (max-width: 1064px) {
  .sax-box {
    margin: 50px 7.1528% 90px 7.2222%;
  }

  .sax-image-right-title-description-card {
    margin: 58px 7.2569% 0 7.2569%;
  }

  .sax-left-content-button-card {
    margin: 80px 7.1875% 0 7.1875%;
  }

  .sax-left-content-button-card > .ax-left-content-button-card {
    padding: 17px 28px;
  }

  .sax-left-content-button-card > .ax-left-content-button-card > .btn-action {
    margin-right: 38px;
  }

  .sax-left-content-button-card > .ax-left-content-button-card > .description {
    margin-right: 104px;
  }

  .sax-left-icon-card1 {
    margin: 72px 7.2222% 0 7.1875%;
  }

  .sax-left-icon-card2 {
    margin: 24px 7.2222% 0 7.1875%;
  }

  .sax-right-content-block1 > .ax-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }

  .sax-right-content-block1 > .ax-right-content-block > .text-box {
    margin-left: 33.5px;
  }

  .sax-right-content-block2 > .ax-right-content-block {
    margin: 0 7.2222% 0 7.1875%;
  }

  .sax-right-content-block2 > .ax-right-content-block > .text-box {
    margin-left: 33.5px;
  }

  .sax-terms {
    margin: 53px 7.2222% 0 7.1875%;
  }

  .sax-terms > .terms {
    padding: 25.5px 24px 24.5px 24px;
  }

  .sax-title1 {
    margin: 110px 7.4132% 0 7.4306%;
  }

  .sax-title2 > .ax-title > .main {
    margin: 0 12.1875% 34px 12.2222%;
  }
}
