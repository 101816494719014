.ax-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.ax-title > .main {
  color: #234171;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  margin-bottom: 20px;
}

.ax-title > .main > .span {
  color: #4378DF;
}

.ax-title > .sub {
  color: #516997;
  font-size: 16px;
  line-height: 19px;
}
