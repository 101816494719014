$spacer: 4rem;

$directions: (
    "t": "-top",
    "l": "-left",
    "b": "-bottom",
    "r": "-right",
    "": "-auto",
);

@for $i from 0 to 6 {
    $spacing: ($spacer * $i * 0.25) !important;

    @each $type, $direction in $directions {
        .m#{$type}-#{$i} {
            margin#{$direction}: $spacing;
        }

        .p#{$type}-#{$i} {
            padding#{$direction}: $spacing;
        }
    }

    .mx-#{$i} {
        margin-left: $spacing;
        margin-right: $spacing;
    }

    .my-#{$i} {
        margin-top: $spacing;
        margin-bottom: $spacing;
    }

    .px-#{$i} {
        padding-left: $spacing;
        padding-right: $spacing;
    }

    .py-#{$i} {
        padding-top: $spacing;
        padding-bottom: $spacing;
    }
}
