.ax-left-image-column-cards-block {
  display: flex;
  flex-wrap: wrap;
}

.ax-left-image-column-cards-block > .image-box {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: 30px;
}

.ax-left-image-column-cards-block > .image-box > .img {
  height: auto;
  width: 100%;
}

.ax-left-image-column-cards-block > .list-box {
  display: grid;
  flex: 1;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 22px 30px;
  grid-template-columns: 1fr 1fr;
}

.ax-left-image-column-cards-block > .list-box > .item {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 31px 25px 25px 25px;
}

.ax-left-image-column-cards-block > .list-box > .item > .description {
  color: #506A96;
  font-size: 16px;
  line-height: 24px;
}

.ax-left-image-column-cards-block > .list-box > .item > .img {
  height: auto;
  width: 34px;
  margin-bottom: 16px;
}

@media screen and (max-width: 539px) {
  .ax-left-image-column-cards-block > .list-box {
    grid-gap: 0;
    grid-template-columns: 1fr;
  }

  .ax-left-image-column-cards-block > .list-box > .item:not(:last-child) {
    margin-bottom: 23px;
  }
}

@media screen and (max-width: 767px) {
  .ax-left-image-column-cards-block > .image-box {
    margin-right: 0;
    min-width: 100%;
  }

  .ax-left-image-column-cards-block > .list-box {
    margin-top: 44px;
  }
}
