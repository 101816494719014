#loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(0deg, #2e5696 0%, #638ee1 100%);
}
